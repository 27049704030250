import { Injectable } from '@angular/core';
import { EmailConfigsEndPoints } from 'src/app/constants/api-endpoints';
import { EmailConfig } from 'src/app/models/auxiliary/email-config.model';
import { ApiService } from 'src/app/sections/common/services/api.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailConfigService {

  emailConfigsUrls = EmailConfigsEndPoints;

  constructor(private _apiService : ApiService) { }

  private returnResponse(response) {
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

  getEmailConfigList(): Observable<EmailConfig> {
    return this._apiService.get(this.emailConfigsUrls.getEmailConfigList, true).pipe(
    map((response: any) => {
      return this.returnResponse(response);
    }));
  }

  saveEmailConfig(emailConfig: EmailConfig): Observable<any> {
    return this._apiService.post(this.emailConfigsUrls.saveEmailConfig, emailConfig, true, true).pipe(
    map((response: any) => {
      return this.returnResponse(response);
    }));
  }

  updateEmailConfig(emailConfig: EmailConfig): Observable<any> {
    return this._apiService.put(this.emailConfigsUrls.editEmailConfig + `/${emailConfig.id.toString()}`,emailConfig, true, true).pipe(
    map((response: any) => {
      return this.returnResponse(response);
    }));
  }

  deleteEmailConfig(id: string): any {
    return this._apiService.delete(this.emailConfigsUrls.deleteEmailConfig, id, true).pipe(
    map((response: any) => {
      return this.returnResponse(response);
    }));
  }

}
