import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './sections/auth/services/auth.service';
import { I18n } from './sections/common/date-picker-i18n';
import { ApiService } from './sections/common/services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Colegios';

  constructor(private translate: TranslateService, private authService: AuthService,private _i18n: I18n, private _apiService: ApiService,) { 
  }

  async ngOnInit() {
    await this._apiService.setBaseUrl();

    const lang = localStorage.getItem('locale');
    if (lang) {
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
      this._i18n.language = lang;
      return;
    }
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|es/) ? browserLang : 'en');
    this._i18n.language = browserLang.match(/en|es/) ? browserLang : 'en';

    if (this.authService.getSessionIsLogin() && this.authService.getLoaclStorageIsLogin()) {
      this.authService.isLogin = true;
    }
  }
}
