import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private defaultLanguage: string = 'en';

  private language$ = new BehaviorSubject<string>(this.defaultLanguage);

  getLanguage(): BehaviorSubject<string>{
    return this.language$;
  }

  languageChanged(language) {
   this.getLanguage().next(language);
  }
  
}
