import { Doctor } from './doctor.model';
import { CouncilResolutionStatus } from '../council-resolutions/council-resolution-status';
import { AdministrativeResource } from '../council-resolutions/administrative-resource';
import { CouncilResolutionDetail } from '../council-resolutions/council-resolution-detail';

export class DoctorCouncilResolution {
    id: number;
    resolutionsType: string;
    topic: string;
    doctorId: number;
    collegiateNumber: number;
    part1: string;
    part2: string;
    part3: string;
    part4: string;
    observations: string;
    isLegalAppeal: boolean;
    councilResolutionStatusId: number;
    createdDate: Date;
    createdBy: number;
    modifiedDate: Date;
    modifiedBy: number;
    doctorName: string;
    councilResolutionStatus: CouncilResolutionStatus;
    doctor: Doctor;
    administrativeResource: AdministrativeResource[];
    councilResolutionDetail: CouncilResolutionDetail[];

    constructor() {
        this.councilResolutionStatus = new CouncilResolutionStatus();
        this.doctor = new Doctor();
        this.administrativeResource = [] as AdministrativeResource[];
        this.councilResolutionDetail = [] as CouncilResolutionDetail[];
    }

}