import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DoctorEndPoints } from '../../../../constants/api-endpoints';
import { Province } from '../../../../models/doctor/province.model';
import { Nationality } from '../../../../models/doctor/nationality.model';
import { ApiService } from 'src/app/sections/common/services/api.service';
import { Town } from 'src/app/models/doctor/doctor-town.model';

@Injectable({
  providedIn: 'root'
})
export class GeneralInformationService {
  doctorUrl = DoctorEndPoints;
  private headerCarousel: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public headerCarousel$: Observable<boolean> = this.headerCarousel.asObservable();
  localeId = 'en';

  constructor(private _apiService : ApiService) { }

  private returnResponse(response) {
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

  updateHeaderCarousel(updateNow) {
    this.headerCarousel.next(updateNow);
  }

  getProvinces(): Observable<Province> {
    return this._apiService.get(this.doctorUrl.provinceList, true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getTowns(): Observable<Town> {
    return this._apiService.get(this.doctorUrl.townList+'?activeOnly=true', true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getNationalists(): Observable<Nationality> {
    return this._apiService.get(this.doctorUrl.nationalityList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  uploadImage(id: string, folderName: string, imageKey: string, image) {
    const formData = new FormData();
    formData.append('folderName', folderName);
    formData.append('id', id);
    formData.append(imageKey, image);

    return this._apiService.upload(this.doctorUrl.imageUpload, formData).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }
}
