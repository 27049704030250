import { Component,Input } from '@angular/core';
import { CompanyLogoConfiguration } from 'src/app/models/auxiliary/company-logo-configuration';
import { CommonDataService } from 'src/app/sections/common/services/common-data.service';
import { CoursePageService } from 'src/app/sections/courses/services/course-page.service';

@Component({
  selector: 'app-courses-attendees-list-report',
  templateUrl: './courses-attendees-list-report.component.html',
  styleUrls: ['./courses-attendees-list-report.component.scss']
})
export class CoursesAttendeesListReportComponent{
  @Input() reportId: string;
  @Input() data: [];
  @Input() localeId: number;
  companyLogo: CompanyLogoConfiguration = null;
  date = new Date();
  constructor(public commonDataService: CommonDataService,public coursePageService:CoursePageService){
    this.companyLogo = new CompanyLogoConfiguration();
  }

  ngOnInit() {
    this.commonDataService.getCompanyLogo().subscribe((labConfiguration: CompanyLogoConfiguration) => {
      this.companyLogo = labConfiguration;
    });
  }
}
