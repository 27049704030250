import { BrowserModule,HAMMER_GESTURE_CONFIG,HammerGestureConfig } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbDatepickerI18n, NgbModule, NgbTypeahead, NgbTypeaheadConfig, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthorizationInterceptor } from './interceptor/authorization.interceptor';
//import { ToastrModule, ToastyService } from 'ng2-toasty';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { registerLocaleData } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { CommonUIService } from './sections/common/services/common.ui.service';
import { CommonFunctions } from './sections/common/services/common-functions';
import localEn from '@angular/common/locales/en';
import localEs from '@angular/common/locales/es';
import { I18n, NgbDateI18 } from './sections/common/date-picker-i18n';
import { ApiService } from './sections/common/services/api.service';
import { AdministrativeDocumentsModuleModule } from './sections/administrative-documents/administrative-documents-module.module';
import { NgbDateCustomParserFormatter } from './sections/common/ngb-date-custom-parser-formatter';
import { PaymentPageRefreshDataService } from './sections/payment/services/payment-page-refresh-data.service';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { UiSwitchModule } from 'ngx-ui-switch';
//import { HttpClientModule } from '@angular/http';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false,
  suppressScrollY: false
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localEn);
registerLocaleData(localEs);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    AdministrativeDocumentsModuleModule,
    NgbTypeaheadModule,
   // HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ positionClass: 'inline' }),
    ToastContainerModule,
    PerfectScrollbarModule,
    SharedModule,
    UiSwitchModule.forRoot({
      size: 'small',
      color: 'rgb(0, 189, 99)',
      switchColor: '#80FFA2',
      defaultBgColor: '#00ACFF',
      defaultBoColor : '#476EFF',
      checkedLabel: 'on',
      uncheckedLabel: 'off'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    ApiService,
    CommonUIService,
    NgbDateCustomParserFormatter,
    PaymentPageRefreshDataService,
    //ToastyService,
    {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthorizationInterceptor,
    multi: true
  },
  {
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  },
  {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: HammerGestureConfig
  },
  { provide: LOCALE_ID, useValue: CommonFunctions.GetBrowserLocale() },
  I18n,
  { provide: NgbDatepickerI18n, useClass: NgbDateI18 },
],
  bootstrap: [AppComponent]
})
export class AppModule {
}
