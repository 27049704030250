import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Issuer } from 'src/app/models/auxiliary/issuer.model';
import { IssuerEndPoints, ViaTypeEndPoints, TownEndPoints, ProvinceEndPoints, CountryEndPoints } from 'src/app/constants/api-endpoints';
import { map } from 'rxjs/operators';
import { ViaType } from 'src/app/models/auxiliary/via-type.model';
import { Town } from 'src/app/models/doctor/doctor-town.model';
import { Province } from 'src/app/models/doctor/province.model';
import { Country } from 'src/app/models/country.model';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {

  issuerUrls = IssuerEndPoints;
  viaTypeUrls = ViaTypeEndPoints;
  townUrls = TownEndPoints;
  provinceUrls = ProvinceEndPoints;
  countryUrls = CountryEndPoints;

  constructor(private _apiService: ApiService) { }

  private returnResponse(response) {
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

  getIssuerList(): Observable<Issuer> {
    return this._apiService.get(this.issuerUrls.getAllIssuerList + "?activeOnly=" + true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getViaTypeList(): Observable<ViaType> {
    return this._apiService.get(this.viaTypeUrls.getViaTypeList + "?activeOnly=" + true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getTownList(): Observable<Town> {
    return this._apiService.get(this.townUrls.getTownList + "?activeOnly=" + true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getProvinceList(): Observable<Province> {
    return this._apiService.get(this.provinceUrls.getProvinceList + "?activeOnly=" + true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getCountryList(): Observable<Country> {
    return this._apiService.get(this.countryUrls.getCountryList + "?activeOnly=" + true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }
}
