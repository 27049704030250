export class DateFormat {
    day: number;  
    month: number;
    year: number;
  
    constructor(_day?: number, _month?: number, _year?: number) {
      this.day = _day;    
      this.month = _month;
      this.year = _year;
    }
  }