import { Province } from './province.model';
import { Country } from '../country.model';

export class Town {
  townName: string;
  provinceId: number;
  countryId: number;
  status: boolean;
  country: Country;
  province: Province;
  id: number;

  constructor(){
    
    this.country = new Country();
    this.province = new Province();
  }
}
