import { Correspondence } from './correspondence.model';

export class CorrespondenceDocument {
    id: number;
    fileName: string;
    generateFileName: string;
    correspondenceId: number;
    extension: string;


    link: string;
    safeUrl: any;
    url: string;

    correspondence: Correspondence;

    constructor() {
        this.correspondence = new Correspondence();
    }

}
