import { Time } from '@angular/common';
import { Student } from './student.model';
import { PriceList } from './price-list';
import { StudentAttendence } from './student-attendence';

export class CourseModule {
    id: number;
    moduleDescription: string;
    startDate: Date;
    finishDate: Date;
    startTime: Time;
    endTime: Time;
    courseId: number;
    capacity: number;
    date: any;
    sTime: string;
    eTime: string;
    createdDate: Date;
    createdBy: number;
    modifiedDate: Date;
    modifiedBy: number;
    startTime2: Time;
    endTime2: Time;
    sTime2: string;
    eTime2: string;
    startTimeSecHalf: Time;
    endTimeSecHalf: Time;
    startTime2SecHalf: Time;
    endTime2SecHalf: Time;
    student: Student[];
    priceList: PriceList[];
    selectedStudents: number[];
    selection: boolean;
    selectedAttendenceStudents: StudentAttendence[];
    studentPagination:{
      page : number,
      pageSize : number,
      maxSize : number,
      totalCount : number,
    };
    studentFilter:{
      paymentStatusId : number
      attendanceStatus:number
    };
}
