import { FilterProfileEndPoints } from './../../../constants/api-endpoints';
import { FilterProfile } from './../../../models/filterProfile.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterProfileService {

  filterProfileUrl = FilterProfileEndPoints;

  constructor(private _apiService: ApiService) { }

  private returnResponse(response) {
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

  getFilterProfileById(id: number): Observable<FilterProfile> {
    return this._apiService.get(this.filterProfileUrl.getFilterProfileById + id, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getFilterProfileList(): Observable<FilterProfile> {
    return this._apiService.get(this.filterProfileUrl.getFilterProfileList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getUserFilterProfileList(userId: number, module: string): Observable<FilterProfile> {
    const url = this.filterProfileUrl.getUserFilterProfileList + "/" + userId + "/" + module;
    return this._apiService.get(url, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveFilterProfile(filterProfile: FilterProfile): Observable<FilterProfile> {
    return this._apiService.post(this.filterProfileUrl.saveFilterProfile, filterProfile, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateFilterProfile(id: number, filterProfile: FilterProfile): Observable<FilterProfile> {
    return this._apiService.put(this.filterProfileUrl.updateFilterProfile + id, filterProfile, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deleteFilterProfile(id: number): Observable<FilterProfile> {
    return this._apiService.delete(this.filterProfileUrl.deleteFilterProfile, id.toString(), true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

}
