import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionCheckService {

  public checkAuthorization(_path: any): boolean {
    // we are loading the roles only once
    return true;
  }

  // private readonly WORKFLOW_EVENTS = environment['workflow'];
  // private userRoles: Set<string>;
  // // do your remember the step 1 ? it is used here
  // constructor(private currentUserService: CurrentUserService) {
  // }
  // // returns a boolean observable
  // public checkAuthorization(path: any): Observable<boolean> {
  //   // we are loading the roles only once
  //   if (!this.userRoles) {
  //     return this.currentUserService.getUser()
  //       .map(currentUser => currentUser.roles)
  //       .do(roles => {
  //         const roles = roles.map(role => role.name);
  //         this.userRoles = new Set(roles);
  //       })
  //       .map(roles => this.doCheckAuthorization(path));
  //   }
  //   return Observable.of(this.doCheckAuthorization(path));
  // }
  //
  // private doCheckAuthorization(path: string[]): boolean {
  //   if (path.length) {
  //     const entry = this.findEntry(this.WORKFLOW_EVENTS, path);
  //     if (entry && entry['permittedRoles']
  //       && this.userRoles.size) {
  //       return entry.permittedRoles
  //         .some(permittedRole => this.userRoles.has(permittedRole));
  //     }
  //     return false;
  //   }
  //   return false;
  // }
  // /**
  //  * Recursively find workflow-map entry based on the path strings
  //  */
  // private findEntry(currentObject: any, keys: string[], index = 0) {
  //   const key = keys[index];
  //   if (currentObject[key] && index < keys.length - 1) {
  //     return this.findEntry(currentObject[key], keys, index + 1);
  //   } else if (currentObject[key] && index === keys.length - 1) {
  //     return currentObject[key];
  //   } else {
  //     return false;
  //   }
  // }
}
