//import { NgxCarouselModule } from 'node_modules/ngx-carousel';
import { NovaCurrencyPipe } from 'src/app/shared/pipes/nova-currency.pipe';
import { CancelReceiptComponent } from './../sections/administrative-documents/cancel-receipt/cancel-receipt.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionDirective } from './directives/permission/permission.directive';
import { HeaderComponent } from './header/header.component';
import { SidePanelComponent } from './side-panel/side-panel.component';
import { ThemeModule } from '../theme/theme.module';
import { NavigationComponent } from './header/navigation/navigation.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { AcceptConfirmationModal } from './accept-confirmation-modal/accept-confirmation-modal.component';
import { ErrorAlertModalComponent } from './error-alert-modal/error-alert-modal.component';
import { ImageEditorComponent } from './image-editor/image-editor.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CarouselComponent } from '../shared/header/carousel/carousel.component';

import 'hammerjs';
import { A11yModule } from '@angular/cdk/a11y';
import { PortalModule } from '@angular/cdk/portal';
import {MatAutocompleteModule} from '@angular/material/autocomplete'
import {MatInputModule} from '@angular/material/input'
//import { MatAutocompleteModule, MatInputModule } from '@angular/material/table';
import { NgxTypeaheadModule } from 'ngx-typeahead';
import { ReceivedEmailsComponent } from './header/received-emails/received-emails.component';
import { SentEmailsComponent } from './header/sent-emails/sent-emails.component';
import { UserPanelComponent } from './header/user-panel/user-panel.component';
import { HttpLoaderSpinnerComponent } from './loader-spinner/http-loader-spinner/http-loader-spinner.component';
import { PreLoaderComponent } from './loader-spinner/pre-loader/pre-loader.component';
import { EditGeneralInformationComponent } from '../sections/doctor/general-information/edit-general-information/edit-general-information.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UserActionsComponent } from './header/user-actions/user-actions.component';
import { AddEntryCorrespondenceComponent } from '../sections/correspondence/entry-correspondence/add-entry-correspondence/add-entry-correspondence.component';
import { AddSentCorrespondenceComponent } from '../sections/correspondence/sent-correspondence/add-sent-correspondence/add-sent-correspondence.component';
import { DateParserFormatter } from '../sections/common/date-parser-formater';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxLoadingSpinnerComponent } from './loader-spinner/ngx-loading/ngx-loading-spinner.component';
import { NgSpinKitModule } from 'ng-spin-kit'
import { AddNewClinicComponent } from '../sections/clinics/clinic-list/add-new-clinic/add-new-clinic.component';
import { AddCouncilResolutionsComponent } from '../sections/council-resolutions/add-council-resolutions/add-council-resolutions.component';
import { CuotasExpandListReportComponent } from './reports/cuotas-expand-list-report/cuotas-expand-list-report.component';
import { CollegiateListStatisticsReportComponent } from './reports/collegiate-list-statistics-report/collegiate-list-statistics-report.component';
import { FilterProfileManagerComponent } from './filter-profile-manager/filter-profile-manager.component';
import { RecallsListReportComponent } from './reports/recalls-list-report/recalls-list-report.component';
import { AddEditRecallComponent } from '../sections/recalls/add-edit-recall/add-edit-recall.component';
import { ClinicListReportComponent } from './reports/clinic-list-report/clinic-list-report.component';
import { ViewCorrespondenceDocumentComponent } from '../sections/correspondence/view-correspondence-document/view-correspondence-document.component';
import { CoursesListReportComponent } from './reports/courses-list-report/courses-list-report.component';
import { CoursesAttendeesListReportComponent } from './reports/courses-attendees-list-report/courses-attendees-list-report.component';
import { InvoiceReportComponent } from './reports/invoice-report/invoice-report.component';
import { EmailSendModalComponent } from './email-send-modal/email-send-modal.component';
import { AddEditInvoiceComponent } from '../sections/administrative-documents/invoice/add-edit-invoice/add-edit-invoice.component';
import { AddEditReceiptComponent } from '../sections/administrative-documents/receipt/add-edit-receipt/add-edit-receipt.component';
import { ReceiptReportComponent } from './reports/receipt-report/receipt-report.component';
import { NovaSearchModule } from 'nova-search';
import { CancelInvoiceComponent } from '../sections/administrative-documents/cancel-invoice/cancel-invoice.component';
import { UserLogsListReportComponent } from './reports/user-logs-list-report/user-logs-list-report.component';
import { CorrespondencesListReportComponent } from './reports/correspondences-list-report/correspondences-list-report.component';
import { EventsListReportComponent } from './reports/events-list-report/events-list-report.component';
import { EditEventsComponent } from '../sections/dashboard/dashboard/events/edit-events/edit-events.component';
import { PrescriptionsListReportComponent } from './reports/prescriptions-list-report/prescriptions-list-report.component';
import { EditPrescriptionsComponent } from '../sections/dashboard/dashboard/prescriptions/edit-prescriptions/edit-prescriptions.component';
import { ViewFileDataComponent } from './view-file-data/view-file-data.component';
import { SocietyExpandListReportComponent } from './reports/society-expand-list-report/society-expand-list-report.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiSwitchModule } from 'ngx-ui-switch';


@NgModule({
  declarations: [PermissionDirective, HeaderComponent, SidePanelComponent, NavigationComponent,
    DeleteConfirmationComponent, ConfirmationComponent, AcceptConfirmationModal, ErrorAlertModalComponent, ImageEditorComponent, CarouselComponent,
    ReceivedEmailsComponent, SentEmailsComponent, UserPanelComponent, HttpLoaderSpinnerComponent, PreLoaderComponent,
    EditGeneralInformationComponent, UserActionsComponent, AddEntryCorrespondenceComponent, AddSentCorrespondenceComponent,
    NgxLoadingSpinnerComponent, AddNewClinicComponent, AddCouncilResolutionsComponent, CuotasExpandListReportComponent,
    CollegiateListStatisticsReportComponent, FilterProfileManagerComponent, RecallsListReportComponent, AddEditRecallComponent,
    ClinicListReportComponent, ViewCorrespondenceDocumentComponent, CoursesListReportComponent, InvoiceReportComponent, ReceiptReportComponent, EmailSendModalComponent,
    AddEditInvoiceComponent, AddEditReceiptComponent, CancelInvoiceComponent, CancelReceiptComponent, NovaCurrencyPipe, UserLogsListReportComponent,
    CorrespondencesListReportComponent, EventsListReportComponent, EditEventsComponent, PrescriptionsListReportComponent, EditPrescriptionsComponent, ViewFileDataComponent,SocietyExpandListReportComponent,CoursesAttendeesListReportComponent],

  imports: [
    CommonModule,
    RouterModule,
    ThemeModule,
    NgbModule,
    ReactiveFormsModule,
    TranslateModule,
    ImageCropperModule,
    ToastrModule.forRoot(),
    ClickOutsideModule,
    //NgxCarouselModule,
    A11yModule,
    PortalModule,
    MatAutocompleteModule,
    MatInputModule,
    NgxTypeaheadModule,
    //CurrencyMaskModule,
    //NgSpinKitModule,
    FormsModule,
    NgSelectModule,
    NovaSearchModule
  ],
  exports: [ThemeModule, NgbModule, PermissionDirective, HeaderComponent, SidePanelComponent,
    DeleteConfirmationComponent, ConfirmationComponent, AcceptConfirmationModal, ErrorAlertModalComponent, ImageEditorComponent, 
    MatInputModule,
    MatAutocompleteModule,
    NgxTypeaheadModule, HttpLoaderSpinnerComponent, PreLoaderComponent, EditGeneralInformationComponent,
    AddSentCorrespondenceComponent, AddEntryCorrespondenceComponent, ClickOutsideModule, 
    //CurrencyMaskModule, 
    //NgSpinKitModule,
    NgxLoadingSpinnerComponent, AddNewClinicComponent, AddCouncilResolutionsComponent, CuotasExpandListReportComponent, CollegiateListStatisticsReportComponent, FilterProfileManagerComponent,
    RecallsListReportComponent, AddEditRecallComponent, ClinicListReportComponent, ViewCorrespondenceDocumentComponent, CoursesListReportComponent, InvoiceReportComponent, ReceiptReportComponent,
    EmailSendModalComponent, AddEditInvoiceComponent, AddEditReceiptComponent, CancelInvoiceComponent, CancelReceiptComponent, NovaCurrencyPipe, UserLogsListReportComponent,
    CorrespondencesListReportComponent, EventsListReportComponent, EditEventsComponent, PrescriptionsListReportComponent, EditPrescriptionsComponent, ViewFileDataComponent,SocietyExpandListReportComponent,CoursesAttendeesListReportComponent],

  providers: [
    { provide: NgbDateParserFormatter, useClass: DateParserFormatter }, NovaCurrencyPipe
  ]
})
export class SharedModule {
}
