import { CommonUIService } from 'src/app/sections/common/services/common.ui.service';
import { AuxillaryService } from 'src/app/sections/common/services/auxillary.service';
import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { DoctorEndPoints } from 'src/app/constants/api-endpoints';
import { Prescription } from 'src/app/models/dashboard/prescription.model';
import { Doctor } from 'src/app/models/doctor/doctor.model';
import { FormGroup } from '@angular/forms';
import { DoctorService } from 'src/app/sections/doctor/services/doctor.service';

import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/sections/auth/services/auth.service';
import { UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { PrescriptionService } from '../services/prescription.service';
import { changeNgbDateToCustomFormat, toNgbDate } from 'src/app/sections/common/services/change-ngb-date-to-custom-format';
import { PrescriptionStatus } from 'src/app/models/dashboard/prescription-status.model';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-prescriptions',
  templateUrl: './edit-prescriptions.component.html',
  styleUrls: ['./edit-prescriptions.component.scss']
})
export class EditPrescriptionsComponent implements OnInit {

  public url = DoctorEndPoints.getDoctorSearchData;
  public search = '';

  @ViewChild('modalLargeNew', { static: false }) child;
  @ViewChild('doctorRef', { static: false }) doctorRef;

  @Output() close: EventEmitter<any> = new EventEmitter();

  submitted = false;
  prescription: Prescription;
  prescriptionForm: FormGroup;
  doctorList: Doctor[];
  doctorId = 0;
  loggedUserId = 0;
  isEdit: boolean;
  isLoading = false;
  prescriptionStatusList: PrescriptionStatus[];
  doctorControl = new UntypedFormControl();

  validationMessages = {  }

  constructor(private formBuilder: UntypedFormBuilder, private prescriptionService: PrescriptionService, private doctorService: DoctorService,
    private toastService: ToastrService, private auxillaryService: AuxillaryService,
    private translationService: TranslateService, private authService: AuthService, private commonUIService : CommonUIService) {
    this.prescription = new Prescription();
    this.initializeForAddPrescription();
    this.doctorList = [] as Doctor[];
    const userDetails = this.authService.getTokenData();
    if (userDetails) {
      this.loggedUserId = userDetails.userId;
    }
    this.prescriptionStatusList = [] as PrescriptionStatus[];
  }

  initializeForAddPrescription() {
    this.prescriptionForm = this.formBuilder.group({
      id: [''],
      doctorId: ['', [Validators.required]],
      prescriptionDate: ['', [Validators.required]],
      prescriptionStatusId: ['']
    });
    this.doctorControl.setValue(null)
  }

  initializeForEditPrescription() {
    const prescriptionDate = moment(this.prescription.prescriptionDate).format('YYYY-MM-DD');
    this.prescriptionForm = this.formBuilder.group({
      id: [this.prescription.id],
      prescriptionDate: [this.prescription.prescriptionDate ? toNgbDate(new Date(this.prescription.prescriptionDate)) : null, [Validators.required]],
      doctorId: [this.search, [Validators.required]],
      prescriptionStatusId: [this.prescription.prescriptionStatusId || ''],
    });
    this.doctorControl.setValue(this.prescription.doctorName? this.prescription.doctorName : null);
    this.doctorRef.nativeElement.click();
  }

  ngOnInit() {
    this.getDoctorList();
    this.getPrescriptionStatusList();
  }

  getDoctorList() {
    this.doctorService.getDoctorList().subscribe(
      (data: any) => {
        if (data._data != null) {
          data._data.forEach((doctor: any) => {
            doctor.fullName = `${doctor.name} ${doctor.surname1} ${doctor.surname2}`;
          });
          this.doctorList = data._data;
        }
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  getPrescriptionStatusList() {
    this.auxillaryService.getPrescriptionStatusList().subscribe(
      (data: any) => {
        this.prescriptionStatusList = data._data;
      }
    );
  }

  public show(prescriptionData): void {
    this.child.show();
    this.submitted = false;
    if (prescriptionData != null) {
      this.prescription = prescriptionData;
    } else {
      this.prescription = new Prescription();
    }
    this.prescriptionForm.reset();
    this.checkIfEditOrAdd();
  }

  checkIfEditOrAdd() {
    if (!this.prescription.id) {
      this.isEdit = false;
      this.initializeForAddPrescription();
      return;
    }
    this.isEdit = true;
    this.doctorId = this.prescription.doctorId;
    this.search = this.prescription.doctorName;
    this.initializeForEditPrescription();
  }

  onSave() {
    this.submitted = true;
    if (!this.commonUIService.validateForm(this.prescriptionForm, this.validationMessages)) {
      return;
    }
    if (!this.prescriptionForm.controls.prescriptionStatusId.value) {
      this.prescriptionForm.controls.prescriptionStatusId.setValue(null);
    }
    if (this.isEdit) {
      this.updatePrescription(this.prescriptionForm.value);
      return;
    }
    this.savePrescription(this.prescriptionForm.value);
  }

  private returnResponse() {
    this.close.emit();
    this.child.hide();
  }


  savePrescription(prescription: Prescription) {
    prescription.id = 0;
    prescription.entryDate = new Date();
    prescription.enteredBy = this.loggedUserId;
    prescription.doctorId = this.doctorId;
    prescription.prescriptionDate = changeNgbDateToCustomFormat(prescription.prescriptionDate).toString();
    this.prescriptionService.savePrescription(prescription).subscribe(
      (_data: any) => {
        this.successToast('prescriptionSave');
        this.returnResponse();
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }


  updatePrescription(prescription: Prescription) {
    prescription.id = this.prescription.id;
    prescription.modifiedDate = new Date();
    prescription.modifiedBy = this.loggedUserId;
    prescription.enteredBy = this.prescription.enteredBy;
    prescription.entryDate = this.prescription.entryDate;
    prescription.doctorId = this.doctorId;
    prescription.prescriptionDate = changeNgbDateToCustomFormat(prescription.prescriptionDate).toString();
    this.prescriptionService.updatePrescription(prescription.id, prescription).subscribe(
      (_data: any) => {
        this.successToast('prescriptionUpdate');
        this.returnResponse();
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  successToast(messageTranslationKey) {
    this.translationService.get(['success', messageTranslationKey]).subscribe((translations: any) => {
      this.toastService.success(translations[messageTranslationKey], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }
  failToast(message) {
    this.translationService.get(['errorTitle', message]).subscribe((translations: any) => {
      this.toastService.error(translations[message], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }


  handleResultSelected(result) {
    if (result) {
      this.search = result.name + ' ' + result.surname1 + ' ' + result.surname2;
      this.doctorId = result.id;
    }
  }

  isDoctorValueSelected(isValueSelected : any){
    if(isValueSelected=='empty') {
      this.prescriptionForm.controls.doctorId.setValue(null);
    }
    //value recieved, form group and control name
    this.commonUIService.novasearchDropDownValidator(isValueSelected,  this.prescriptionForm, 'doctorId');
  }

  onClearDoctor() {
    this.doctorControl.setValue(null);
    this.prescriptionForm.controls.doctorId.setValue(null);
    this.doctorId = null;
  }

  get prescriptionDate() {
    return this.prescriptionForm.get('prescriptionDate');
  }

  get doctorid() {
    return this.prescriptionForm.get('doctorId');
  }

  get prescriptionStatusId() {
    return this.prescriptionForm.get('prescriptionStatusId');
  }



}
