import { User } from './../user.model';
import { RecallType } from './recall-type.model';
import { RecallCategory } from '../auxiliary/recall-category.model';
import { RecallClass } from './recall-class.model';

export class Recall {
    id: number;
    doctorId: number;
    recallTypeId: number;
    recallCategoryId: number;
    description: string;
    recallDate: Date;
    recallStatus: boolean;
    createdDate: Date;
    createdBy: number;
    modifiedDate: Date;
    modifiedBy: number;
    doctorName: string;
    date: any;
    time: string;
    timeFormatted: string;
    institutionOrPerson: string;
    recallTypeName: string;
    recallCategoryName: string;
    recallClassName: string;
    recallClassId: number;
    recallFormOfContactId: number;
    userId: number;
    userName: string;


    recallType: RecallType;
    recallCategory: RecallCategory;
    recallClass: RecallClass;
    user: User;

    constructor() {
        this.recallType = new RecallType();
        this.recallCategory = new RecallCategory();
        this.recallClass = new RecallClass();
        this.user = new User();
    }
}
