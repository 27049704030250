import { Injectable } from '@angular/core';
import { AuxiliaryTranslateValue } from 'src/app/models/common/auxiliary-translate-values';
import { AuxillaryService } from 'src/app/sections/common/services/auxillary.service';
import { CompanyLogoConfiguration } from 'src/app/models/auxiliary/company-logo-configuration';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonDataService {

  auxiliaryTranslateValue: AuxiliaryTranslateValue;

  constructor(private auxillaryService: AuxillaryService) {
    this.auxiliaryTranslateValue = new AuxiliaryTranslateValue();
   }

  // download base 64 string file
  public DownloadFile(file: any, fileName: string): void {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = 'data:application/octet-stream;base64,' + file;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public getAuxillaryTranslateById(key: any, value: any, locale:any): string {
    if (key && value && locale && this.auxiliaryTranslateValue[key]) {
      const item = this.auxiliaryTranslateValue[key].find(v => v.value == value)
      if (item && item[locale]) {
        return item[locale];
      }
      return "";
    }
    return "";
  }

  public getAuxillaryTranslateByTrueFalse(key: any, value: any, locale:any): string {
    if (key  && locale && this.auxiliaryTranslateValue[key]) {
      const item = this.auxiliaryTranslateValue[key].find(v => v.value == value)
      if (item && item[locale]) {
        return item[locale];
      }
      return "";
    }
    return "";
  }

  getCompanyLogo(): Observable<CompanyLogoConfiguration> {
    return this.auxillaryService.getCompanyLogoList()
      .pipe(map((data: any) => {
        const labConfiguration = new CompanyLogoConfiguration();

        if (data._data != null) {
          const companyLogo = data._data.find(l=>l.logoKey == "college_logo")

          if (companyLogo) {
            const element = companyLogo;
            labConfiguration.id = element.id;
            labConfiguration.image = element.image;
            labConfiguration.imageData = 'data:image/png;base64,' + element.imagePathUrl;
          } else {
            labConfiguration.id = null;
            labConfiguration.imageData = undefined;
          }
        } else {
          labConfiguration.id = null;
          labConfiguration.imageData = undefined;
        }
        return labConfiguration;
      }));
  }
}
