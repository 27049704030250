import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IHeaderState } from './interfaces/header.interface';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private headerCorrespondenceUpdateSubject = new Subject<IHeaderState>();
  headerCorrespondenceUpdateState = this.headerCorrespondenceUpdateSubject.asObservable();

  update() {
    this.headerCorrespondenceUpdateSubject.next({updateCorrespondence: true} as IHeaderState);
  }

  stopUpdate() {
    this.headerCorrespondenceUpdateSubject.next({updateCorrespondence: false} as IHeaderState);
  }
}
