
export const UserSearchPath = {
  module: 'Users',
  path: 'user/user-list'
}

export const DoctorSearchPath = {
  module: 'Doctor',
  path: 'doctor/doctor-details/'
}

export const ClinicSearchPath = {
  module: 'Clinic',
  path: 'clinic-page/clinic-details/'
}

export const SocietySearchPath = {
  module: 'Society',
  path: 'society-page/society'
}

export const CourseSearchPath = {
  module: 'Course',
  path: 'course-page/course-details'
}

export const CouncilResolutionSearchPath = {
  module: 'CouncilResolution',
  path: 'council-resolution-page/detail-council-resolutions/'
}

export const JudicialResolutionSearchPath = {
  module: 'JudicialResolution',
  path: 'juditial-resolution-page/juditial-resolution-detail/'
}
