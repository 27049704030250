import { DoctorService } from 'src/app/sections/doctor/services/doctor.service';
import { SeriesGroupService } from './../../../auxillary-tables/series-year/Services/series-group.service';
import { IssuerService } from './../../../auxillary-tables/issuer/services/issuer.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SortList } from 'src/app/sections/common/sortList';
import { UntypedFormBuilder, UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { TSeries } from 'src/app/models/auxiliary/tseries.model';
import { DropDownOption } from 'src/app/models/common/dropdown-option';
import { changeNgbDateToCustomFormat, toNgbDate } from 'src/app/sections/common/services/change-ngb-date-to-custom-format';
import { AuthService } from 'src/app/sections/auth/services/auth.service';
import { Receipt } from 'src/app/models/administrative-documents/receipt.model';
import { EconomicAct } from 'src/app/models/administrative-documents/economic-act.model';
import { ReceiptService } from '../services/receipt.service';
import { ReceiptDetail } from 'src/app/models/administrative-documents/receipt-detail.model';
import { ToastrService} from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Doctor } from 'src/app/models/doctor/doctor.model';
import { CommonUIService } from 'src/app/sections/common/services/common.ui.service';

@Component({
  selector: 'app-add-edit-receipt',
  templateUrl: './add-edit-receipt.component.html',
  styleUrls: ['./add-edit-receipt.component.scss']
})
export class AddEditReceiptComponent implements OnInit {

  @Input() doctorId: number;
  @Output() handleSave: EventEmitter<any> = new EventEmitter();

  issuersList: DropDownOption[] = [];
  seriesList: DropDownOption[] = [];
  economicActsList: EconomicAct[] = [];
  selectedEconomicActsList: EconomicAct[] = [];
  doctorsList: { id: string, name: string }[] = [];
  selection = false;
  isLoading = false;
  isEconomicActsLoading = false;
  submitted = false;
  localeId = 'en';
  maxNumberSize = 5;
  loggedUserId = 0;
  studentId: number;

  formGroup: FormGroup;

  issuerFormControl = new UntypedFormControl();
  seriesFormControl = new UntypedFormControl();
  collegiateFormControl = new UntypedFormControl();

  filters = {
    originId: 1,
    collegiateIds: 0,
    paymentId: 0,
    fromDate: null,
    toDate: null,
    type: 2,
    economicActsIds: "0",
  }

  page = 1;
  pageSize = 10;
  totalCount = 0;
  maxSize = 10;

  selectedOriginId = 0;
  selectedPaymentId = 1;
  isDoctorPage = false;

  validationMessages = {
    generateDate: {
      ngbDate: {
        invalid: 'common.validations.invalidDate',
      }
    }
  }


  @ViewChild('modalLargeNew', { static: false }) child;

  constructor(private seriesGroupService: SeriesGroupService, private issuerService: IssuerService, private doctorService: DoctorService,
    private _fb: UntypedFormBuilder, private authService: AuthService, private receiptService: ReceiptService, private toastService: ToastrService,
    private translationService: TranslateService, private commonUIService: CommonUIService) {
    const userDetails = this.authService.getTokenData();
    if (userDetails) {
      this.loggedUserId = userDetails.userId;
    }
  }

  ngOnInit() {
    this.initializeForm();
    this.getDoctorsList();
    this.filters.collegiateIds = this.doctorId;
  }

  getLocaleId(prefix: string) {
    this.localeId = prefix;
  }

  show(economicsActs: any[], originId = 3, parentId = 0) {
    this.selectedOriginId = 0;
    this.page = 1;
    this.selectedEconomicActsList = [];
    this.submitted = false;
    this.issuerFormControl.setValue(null);
    this.seriesFormControl.setValue(null);
    this.collegiateFormControl.setValue(null);
    this.child.show();
    this.getIssuers();
    this.seriesGroupService.getTseriesListBySelectedYear();

    if (economicsActs && economicsActs.length) {
      this.selectedOriginId = originId;
      this.filters.originId = originId;
      this.filters.economicActsIds = economicsActs.map(m => m.id).join(',')
      this.getEconomicActs();
      this.doctorId = economicsActs[0].doctorId;
      this.studentId = economicsActs[0].studentId;
    }else{
      this.filters.economicActsIds = "0";
    }
    if (originId == 1 || originId == 2) {
      this.filters.paymentId = parentId;
      this.filters.originId = originId;
      this.selectedOriginId = originId;
      this.selectedPaymentId = parentId;
    } else {
      this.isDoctorPage = parentId == -1;
      this.selectedPaymentId = 0;
      this.filters.paymentId = 0;
    }
    if (this.doctorId != 0 || parentId != 0) {
      this.getEconomicActs();
    }
    this.initializeForm();
  }

  initializeForm() {
    this.formGroup = this._fb.group({
      generateNumber: [null, [Validators.required]],
      doctorId: [this.doctorId],
      studentId: [this.studentId],
      originId: [this.selectedOriginId, [Validators.required]],
      generateDate: [toNgbDate(new Date()), [Validators.required]],
      issuerId: [null, [Validators.required]],
      seriesId: [null, [Validators.required]],
      discount: [0],
      tax: [0],
      subTotal: [0],
      comments: [null],
      status: [1],
      discountAmount: [0],
      receiptTotal: [0],
    });

  }

  getIssuers() {
    this.issuersList = [];
    this.issuerService.getIssuersList().subscribe(
      (data: any) => {
        this.issuersList = SortList(data._data, 'name').map(issuer => {
          return new DropDownOption(issuer.id, issuer.issuerDescription)
        });

      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  clearFilters() {
    this.filters = {
      originId: this.selectedOriginId,
      collegiateIds: this.doctorId,
      paymentId: this.selectedPaymentId,
      fromDate: null,
      toDate: null,
      type: 2,
      economicActsIds: "0",
    }
    this.collegiateFormControl.setValue(null);
    this.getEconomicActs();
  }

  getEconomicActs() {
    this.economicActsList = [];
    const filters = {
      ...this.filters,
      fromDate: this.filters.fromDate ? changeNgbDateToCustomFormat(this.filters.fromDate) : null,
      toDate: this.filters.toDate ? changeNgbDateToCustomFormat(this.filters.toDate) : null
    }
    if (this.filters.collegiateIds != 0 || this.filters.economicActsIds != "0" || this.filters.paymentId != 0) {
      this.isEconomicActsLoading = true;
      this.doctorService.getEconomicActsByDoctor(this.pageSize, this.page, filters).subscribe(
        (data: any) => {
          this.economicActsList = data._data.map(item => ({ ...item, isIncluded: !!this.selectedEconomicActsList.find(a=>a.id==item.id ) }));
          this.page = data.page;
          this.totalCount = data.totalCount;
          this.isEconomicActsLoading = false;
        },
        (error) => {
          this.failToast(error._message);
          this.isEconomicActsLoading = false;
        }
      );
    }
  }

  getDoctorsList() {
    this.doctorService.getDoctorList().subscribe(
      (data: any) => {
        if (data._data != null) {
          this.doctorsList = SortList(data._data as Doctor[], 'name').map(doctor => ({ id: doctor.id, name: `${doctor.collegiateNumber} - ${doctor.name} ${doctor.surname1}` }));
        }

      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  onIssuerSelected(issuer) {
    this.seriesList = this.seriesGroupService.seriesList.filter(series => series.issuerId == issuer.value && series.seriesType == "Receipt" && series.status).map(series => {
      return new DropDownOption(series.id, series.series)
    });
    this.formGroup.controls.issuerId.setValue(issuer.value);
    this.formGroup.controls.seriesId.setValue(null);
  }

  onSeriesSelected(selection) {
    const series: TSeries = this.seriesGroupService.seriesList.find(s => s.id == selection.value)
    const prefix = series.series.toUpperCase() + '-';
    const _year = parseInt(series.year) > 0 ? series.year.slice(-2) : new Date().getFullYear().toString().slice(-2);
    const yearSuffix = '/' + _year;
    const numToBeUsed = series.lastNumber;
    const gno = prefix + numToBeUsed.toString().padStart(this.maxNumberSize, '0') + yearSuffix;
    this.formGroup.controls.generateNumber.setValue(gno);
    this.formGroup.controls.seriesId.setValue(selection.value);
  }

  onDoctorSelect(data) {
    this.formGroup.controls.doctorId.setValue(data.id);
    this.filters.collegiateIds = data.id;
  }
  onClearDoctorList() {
    this.formGroup.controls.doctorId.setValue(null);
    this.filters.collegiateIds = 0;
  }


  onChangeSelection() {
    if (this.selection) {
      const filters = {
        ...this.filters,
        fromDate: this.filters.fromDate ? changeNgbDateToCustomFormat(this.filters.fromDate) : null,
        toDate: this.filters.toDate ? changeNgbDateToCustomFormat(this.filters.toDate) : null
      }
      this.doctorService.getEconomicActsByDoctor(this.totalCount, 1, filters).subscribe(
        (data: any) => {
          this.economicActsList = this.economicActsList.map(item => ({ ...item, isIncluded: true }));
          const allList = data._data.map(item => ({ ...item, isIncluded: true }));
          this.selectedEconomicActsList = [...allList];
        },
        (error) => {
          this.failToast(error._message);
        }
      );
    } else {
      this.economicActsList = this.economicActsList.map(item => ({ ...item, isIncluded: false }));
      this.selectedEconomicActsList = [];
    }
  }

  onSelect(_event, economicAct) {
    this.economicActsList.forEach(_economicAct => {
      if (_economicAct.id == economicAct.id) {
        _economicAct.isIncluded = !_economicAct.isIncluded
        if (_economicAct.isIncluded) {
          this.selectedEconomicActsList.push(_economicAct)
        } else {
          this.selection = false;
          this.selectedEconomicActsList = this.selectedEconomicActsList.filter(item => item.id != _economicAct.id)
        }
      }
    })
  }

  getTotal() {
    return this.selectedEconomicActsList.reduce((accumulator, object) => {
      return accumulator + object.totalAmount;
    }, 0);
  }

  getSelectedTotal(selectedEconomicActsList) {
    return selectedEconomicActsList.reduce((accumulator, object) => {
      return accumulator + object.totalAmount;
    }, 0);
  }


  onSave() {
    this.submitted = true;
    if (!this.formGroup.controls.issuerId.value) {
      this.formGroup.controls.issuerId.setValue(null);
    }
    if (!this.formGroup.controls.seriesId.value) {
      this.formGroup.controls.seriesId.setValue(null);
    }
    this.formGroup.controls.originId.setValue(this.filters.originId);
    if (!this.commonUIService.validateForm(this.formGroup, this.validationMessages) || this.selectedEconomicActsList.length == 0) {
      return;
    }
    this.saveReceipt(this.formGroup.value);
  }

  handleClose() {
    this.economicActsList = [];
    this.selectedEconomicActsList = [];
    this.child.hide();
    this.isLoading = false;
    this.selectedOriginId = 1;
    this.filters.originId = 1;
    this.filters.paymentId = 0;
    this.doctorId = 0;
    this.filters.economicActsIds = "0";
  }

  saveReceipt(receipt: Receipt) {
    receipt.id = 0;
    receipt.createdDate = new Date();
    receipt.createdBy = this.loggedUserId;
    receipt.modifiedDate = new Date();
    receipt.modifiedBy = this.loggedUserId;
    receipt.subTotal = this.getTotal();
    receipt.receiptTotal = this.getTotal();
    this.isLoading = true;

    let doctorsReceiptsList = this.selectedEconomicActsList.reduce((r, a) => {
      const field = this.selectedOriginId == 3 ? 'studentId' : 'doctorId';
      r[a[field]] = r[a[field]] || [];
      r[a[field]].push(a);
      return r;
    }, Object.create(null));

    const receipts = [];
    Object.keys(doctorsReceiptsList).forEach(key => {
      const _receipt = { ...receipt };
      _receipt.doctorId = doctorsReceiptsList[key][0].doctorId
      _receipt.studentId = doctorsReceiptsList[key][0].studentId
      _receipt.subTotal = this.getSelectedTotal(doctorsReceiptsList[key]);
      _receipt.receiptTotal = this.getSelectedTotal(doctorsReceiptsList[key]);
      _receipt.receiptDetail = doctorsReceiptsList[key].map(ea => {
        _receipt.generateDate = changeNgbDateToCustomFormat(_receipt.generateDate);
        const receiptDetail = new ReceiptDetail();
        receiptDetail.economicActId = ea.id;
        return receiptDetail;
      });
      receipts.push(_receipt);
    });

    this.receiptService.saveReceipt(receipts).subscribe(
      (data: any) => {
        this.successToast('common.savedSuccessfully');
        this.handleClose();
        this.handleSave.emit(data._data[data._data.length-1]);
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  successToast(messageTranslationKey) {
    this.translationService.get(['success', messageTranslationKey]).subscribe((translations: any) => {
      this.toastService.success(translations[messageTranslationKey], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }

  failToast(message) {
    this.translationService.get(['errorTitle', message]).subscribe((translations: any) => {
      this.toastService.error(translations[message], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }

  get generateDate() {
    return this.formGroup.get('generateDate');
  }

  get generateNumber() {
    return this.formGroup.get('generateNumber');
  }

  get issuerId() {
    return this.formGroup.get('issuerId');
  }

  get seriesId() {
    return this.formGroup.get('seriesId');
  }

  get comments() {
    return this.formGroup.get('comments');
  }


}
