import {Component, Inject, Input, OnDestroy, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {HttpLoaderSpinnerService} from './service/http-loader-spinner.service';
import {LoaderState} from './interface/http-loader.interface';

@Component({
  selector: 'app-http-loader-spinner',
  templateUrl: './http-loader-spinner.component.html',
  styleUrls: ['./http-loader-spinner.component.scss',
    './spinkit-css/sk-line-material.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HttpLoaderSpinnerComponent implements OnDestroy {
  @Input() public isSpinnerVisible = true;
  @Input() public backgroundColor = 'rgba(255, 255, 255, 0.8)';

  private subscription: Subscription;

  constructor(private router: Router, @Inject(DOCUMENT) private document: Document, private loaderService: HttpLoaderSpinnerService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isSpinnerVisible = true;
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.isSpinnerVisible = false;
      }
    }, () => {
      this.isSpinnerVisible = false;
    });

    this.subscription = this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        this.isSpinnerVisible = state.show;
      });
  }

  ngOnDestroy(): void {
    this.isSpinnerVisible = false;
    this.subscription.unsubscribe();
  }
}
