import { CancelInvoice } from './../../../../models/administrative-documents/cancel-invoice.model';
import { Injectable } from '@angular/core';
import { CancelInvoiceEndPoints } from 'src/app/constants/api-endpoints';
import { ApiService } from 'src/app/sections/common/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CancelInvoiceService {

  invoiceUrls = CancelInvoiceEndPoints;

  constructor(private _apiService: ApiService) { }

  private returnResponse(response) {
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

  getInvoiceById(id: number) {
    return this._apiService.get(this.invoiceUrls.getCancelInvoiceById.replace('{id}', id.toString()), true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveInvoice(invoice: CancelInvoice): Observable<CancelInvoice> {
    return this._apiService.post(this.invoiceUrls.saveCancelInvoice, invoice, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }
}
