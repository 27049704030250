import {Component, ViewChild} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error-alert-modal',
  templateUrl: './error-alert-modal.component.html',
  styleUrls: ['./error-alert-modal.component.scss']
})
export class ErrorAlertModalComponent {
  @ViewChild('modalLarge', { static: false }) child;
  title: string;
  message: string;

  constructor(private translationService: TranslateService) { }

  public show(title, message): void {
    setTimeout(() => {
      this.title = title;
      this.translate(message);
      this.child.show();
    }, 25);
  }

  translate(key) {
    this.translationService.get([key]).subscribe((translations: any) => {
      this.message = translations[key];
    });
  }

}
