import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Delay } from 'src/app/sections/common/delay-helper';

@Component({
  selector: 'app-view-file-data',
  templateUrl: './view-file-data.component.html',
  styleUrls: ['./view-file-data.component.scss']
})
export class ViewFileDataComponent {
  @ViewChild('modalLargeNew', { static: false }) child;
  submitted = false;
  fileData: any;

  constructor(private sanitizer: DomSanitizer, private http: HttpClient) {
    this.fileData = new Object();
  }

  public async show(fileData: any) {
    if (fileData) {
      if (fileData != null) {
        this.fileData = fileData;
        this.fileData.safeUrl = this.fileData.url
        ? this.sanitizer.bypassSecurityTrustResourceUrl(this.fileData.url) : undefined;
        await Delay(500);
        this.child.show();
        this.submitted = false;
      }
    }
  }

  onClickFileData(fileData: any) {
    if (fileData) {
      window.open(fileData.link, '_blank');
    }
  }

  closeModel() {
    this.fileData = null;
    this.child.hide();
  }
}
