import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent {
  @ViewChild('modalLarge', { static: false }) child;
  @Output() confirmDeleteEvent: EventEmitter<any> = new EventEmitter();
  @Output() confirmDeleteCourse: EventEmitter<any> = new EventEmitter();
  @Output() confirmDeletePrescription: EventEmitter<any> = new EventEmitter();
  @Output() confirmDeleteModule: EventEmitter<any> = new EventEmitter();
  @Output() confirmDeleteRecall: EventEmitter<any> = new EventEmitter();

  data: any;
  title: string;
  message: string;

  constructor(private translationService: TranslateService) {
  }

  public show(title, message, data = {}, messageKey?: string): void {
    setTimeout(() => {
      this.data = data;
      this.translate(title);
      if (messageKey) {
        this.translateData(messageKey);
      } else {
        this.message = message;
      }
      this.child.show();
    }, 25);
  }

  translate(key) {
    this.translationService.get([key]).subscribe((translations: any) => {
      this.title = translations[key];
    });
  }

  translateData(key) {
    this.translationService.get([key]).subscribe((translations: any) => {
      this.message = translations[key];
    });
  }

  confirmDelete() {
    this.confirmDeleteEvent.emit(this.data);
    this.child.hide();

    this.confirmDeleteCourse.emit(this.data);
    this.child.hide();

    this.confirmDeletePrescription.emit(this.data);
    this.child.hide();

    this.confirmDeleteModule.emit(this.data);
    this.child.hide();

    this.confirmDeleteRecall.emit(this.data);
    this.child.hide();
  }

}
