import { Component, Input } from '@angular/core';
import { CompanyLogoConfiguration } from 'src/app/models/auxiliary/company-logo-configuration';
import { CommonDataService } from 'src/app/sections/common/services/common-data.service';

@Component({
  selector: 'app-collegiate-list-statistics-report',
  templateUrl: './collegiate-list-statistics-report.component.html',
  styleUrls: ['./collegiate-list-statistics-report.component.scss']
})
export class CollegiateListStatisticsReportComponent {

  @Input() reportId: string;
  @Input() data :[];
  companyLogo: CompanyLogoConfiguration = null;

  date= new Date();
  constructor(public commonDataService: CommonDataService) {
    this.companyLogo = new CompanyLogoConfiguration();
  }
  ngOnInit() {
    this.commonDataService.getCompanyLogo().subscribe((labConfiguration: CompanyLogoConfiguration) => {
      this.companyLogo = labConfiguration;
    });
  }
}
