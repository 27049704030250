import { Injectable } from '@angular/core';
import { EmailEndPoints } from 'src/app/constants/api-endpoints';
import { ApiService } from './api.service';
import { SendEmail } from 'src/app/models/common/SendEmail';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  emailUrls = EmailEndPoints;

  constructor(private _apiService : ApiService) { }

  private returnResponse(response) {
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

  sendEmail(emailModel: SendEmail): Observable<SendEmail> {
    return this._apiService.post(this.emailUrls.sendEmail, emailModel, true).pipe(
        map((response: any) => {
        return this.returnResponse(response);
      }));
  }
}
