import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss']
})
export class ImageEditorComponent {
  @Input() title = 'Image crop';
  @Output() imageChangeEventOutput: EventEmitter<any> = new EventEmitter();
  croppedImage: any;
  @ViewChild('modalLargeNew', { static: false }) child;
  imageChangedEvent: any = '';
  imageObject: any;

  public show(image): void {
    this.croppedImage = image;
    this.child.show();
  }

  onSave() {
    this.child.hide();
    this.imageChangeEventOutput.emit(this.imageObject);
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.imageObject = event;
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

}
