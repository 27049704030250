import { CommonService } from './../../../common/services/common.service';
import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { Clinic } from '../../../../models/clinic.model';
import { ClinicService } from '../../services/clinic.service';

import { TranslateService } from '@ngx-translate/core';
import { AuxillaryService } from '../../../../sections/common/services/auxillary.service';
import { Town } from '../../../../models/doctor/doctor-town.model';
import { Router } from '@angular/router';
import { SortList } from 'src/app/sections/common/sortList';
import { CommonUIService } from 'src/app/sections/common/services/common.ui.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-new-clinic',
  templateUrl: './add-new-clinic.component.html',
  styleUrls: ['./add-new-clinic.component.scss']
})
export class AddNewClinicComponent implements OnInit {
  @ViewChild('modalLargeNew', { static: false }) child;
  clinicForm: FormGroup;
  clinic: Clinic;
  @Output() close: EventEmitter<any> = new EventEmitter();
  isLoading = false;
  submitted = false;
  townID: number;
  townList: Town[];
  public search = '';
  isFromSociety = false;
  townControl = new UntypedFormControl();


  validationMessages = {
    email: {
      email: 'invalidEmailAddress'
    }
  }

  constructor(private formBuilder: UntypedFormBuilder, private clinicService: ClinicService, private auxillaryService: AuxillaryService,
    private toastService: ToastrService, private translationService: TranslateService, private router: Router, private commonUIService: CommonUIService) {
    this.clinic = new Clinic();
    this.townList = [] as Town[];
    this.initializeForAddClinicInformation();
  }

  ngOnInit() {
    this.getTownList();
  }

  initializeForAddClinicInformation() {
    this.clinicForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      typeOfVia: [''],
      nameOfVia: [''],
      number: [''],
      door: [''],
      townId: [''],
      privateTelephone: [''],
      workTelephone: [''],
      niforCif: [''],
      userRido: [''],
      sanitaryNumber: [''],
      email: ['', [Validators.email]],
      countryName: [''],
      provinceName: [''],
      mobileNumber: [''],
    });
  }

  public show(status): void {
    this.isFromSociety = false;
    this.isFromSociety = status ? status : false;
    this.townID = null;
    this.clinic.countryId = null;
    this.clinic.provinceId = null;
    this.submitted = false;
    this.clinicForm.reset();
    this.child.show();
  }

  onSave() {
    this.submitted = true;
    if (!this.commonUIService.validateForm(this.clinicForm, this.validationMessages)) {
      return;
    }
    this.saveClinic(this.clinicForm.value);
  }

  getTownList() {
    this.auxillaryService.getTownList().subscribe(
      (data: any) => {
        if (data._data != null) {
          this.townList = SortList(data._data, 'townName');
        }
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  searchTownByText(_$event) {
    this.clinicForm.controls.provinceName.setValue('');
    this.clinicForm.controls.countryName.setValue('');
    this.townID = null;
    this.clinic.countryId = null;
    this.clinic.provinceId = null;
  }

  onSelectTown(result) {
    if (result) {
      this.search = result.townName;
      this.townID = result.id;
    }
    const town = this.townList.filter(x => x.id == this.townID)[0];
    this.clinicForm.controls.provinceName.setValue(town.province.provinceName);
    this.clinicForm.controls.countryName.setValue(town.country.countryName);
    this.clinic.countryId = town.countryId;
    this.clinic.provinceId = town.provinceId;
  }

  saveClinic(clinic: Clinic) {
    clinic.id = 0;
    clinic.townId = this.townID;
    clinic.provinceId = this.clinic.provinceId;
    clinic.countryId = this.clinic.countryId;
    this.isLoading = true;
    this.clinicService.saveClinic(clinic).subscribe(
      (data: any) => {
        this.successToast('saveClinic');
        this.isLoading = false;
        if (this.isFromSociety) {
          this.close.emit(data._data.id);
        } else {
          this.router.navigate(['/clinic/clinic-details/' + data._data.id]);
          this.close.emit(data._data.id);
        }
        this.child.hide();
      },
      (error) => {
        if (error._message) {
          this.failToast(error._message);
          return;
        }
        this.failToast('unknownError');
        this.isLoading = false;
      }
    );
  }

  successToast(messageTranslationKey) {
    this.translationService.get(['success', messageTranslationKey]).subscribe((translations: any) => {
      this.toastService.success(translations[messageTranslationKey], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }

  failToast(message) {
    this.translationService.get(['errorTitle', message]).subscribe((translations: any) => {
      this.toastService.error(translations[message], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }

  isTownValueSelected(isValueSelected: any) {
    if (isValueSelected == 'empty') {
      this.clinicForm.controls.townId.setValue(null);
    }
    //value recieved, form group and control name
    this.commonUIService.novasearchDropDownValidator(isValueSelected, this.clinicForm, 'townId');
  }

  onClearTown() {
    this.townControl.setValue(null);
    this.clinicForm.controls.townId.setValue(null);

    this.clinicForm.controls.provinceName.setValue(null);
    this.clinicForm.controls.countryName.setValue(null);
    this.clinic.countryId = 0;
    this.clinic.provinceId = 0;
    this.clinic.townId = null;
  }

  get name() {
    return this.clinicForm.get('name');
  }
  get typeOfVia() {
    return this.clinicForm.get('typeOfVia');
  }
  get nameOfVia() {
    return this.clinicForm.get('nameOfVia');
  }
  get number() {
    return this.clinicForm.get('number');
  }
  get door() {
    return this.clinicForm.get('door');
  }
  get townId() {
    return this.clinicForm.get('townId');
  }
  get privateTelephone() {
    return this.clinicForm.get('privateTelephone');
  }
  get sanitaryNumber() {
    return this.clinicForm.get('sanitaryNumber');
  }
  get userRido() {
    return this.clinicForm.get('userRido');
  }
  get email() {
    return this.clinicForm.get('email');
  }
  get mobileNumber() {
    return this.clinicForm.get('mobileNumber');
  }
  get workTelephone() {
    return this.clinicForm.get('workTelephone');
  }
  get niforCif() {
    return this.clinicForm.get('niforCif');
  }
  get provinceName() {
    return this.clinicForm.get('provinceName');
  }
  get countryName() {
    return this.clinicForm.get('countryName');
  }

}
