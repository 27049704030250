import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { RecallEndPoints } from 'src/app/constants/api-endpoints';
import { Observable } from 'rxjs';
import { Recall } from 'src/app/models/dashboard/recall.model';
import { map } from 'rxjs/operators';
import { ApiService } from '../../common/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class RecallPageService {

  recallUrl = RecallEndPoints;

  constructor(private _apiService : ApiService) { }

  private returnResponse(response) {
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

  getRecallById(id: number): Observable<Recall> {
    return this._apiService.get(this.recallUrl.getRecallById + id, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getRecallList(): Observable<Recall> {
    return this._apiService.get(this.recallUrl.getRecallList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveRecall(recall: Recall): Observable<Recall> {
    return this._apiService.post(this.recallUrl.saveRecall, recall, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateRecall(id: number, recall: Recall): Observable<Recall> {
    return this._apiService.put(this.recallUrl.updateRecall + id, recall, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deleteRecall(id: number): Observable<Recall> {
    return this._apiService.delete(this.recallUrl.deleteRecall, id.toString(), true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  RecallPaginated(pageSize: number, page: number, recallClassId: string, recallCategoryId: string, recallTypeId :number, recallStatus:any, userId:string, fromdate: string, todate: string,loggedUserId:number): Observable<Recall> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('filter', fromdate + ',' + todate + ',' + recallClassId + ',' + recallCategoryId + ',' + recallTypeId + ',' + recallStatus + ',' + userId+ ',' + loggedUserId);

      const query = params.toString().replace('+', '%20');
      return this._apiService.get(this.recallUrl.recallListPagination + '?' + `${query}`, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  recallListExport(pageSize: number, page: number, recallClassId: string, recallCategoryId: string, recallTypeId :number, recallStatus:any, userId:string, fromdate: string, todate: string,loggedUserId:number): Observable<Recall> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('filter', fromdate + ',' + todate + ',' + recallClassId + ',' + recallCategoryId + ',' + recallTypeId + ',' + recallStatus + ',' + userId+ ',' + loggedUserId);

      const query = params.toString().replace('+', '%20');
      return this._apiService.get(this.recallUrl.recallListExport + '?' + `${query}`, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getRecallRowNumberByRecallId(id: number): Observable<number> {
    return this._apiService.get(this.recallUrl.getRecallRowNumberByRecallId + id, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

}
