
export class AuxiliaryTranslateValue {

    courseCreditList: any[] = [{ value: 1, en: 'Provide Credit', es: 'Aporta Créditos' },
    { value: 2, en: 'Not Provide Credit', es: 'No aporta Créditos' }];

    courseTypeList: any[] = [{ value: 1, en: 'Simple', es: 'Simple' },
    { value: 2, en: 'Various Modules', es: 'Varios Módulos' },
    { value: 3, en: 'Independent Module', es: 'Módulos Independientes' }];

    courseStatusList: any[] = [{ value: 1, en: 'Free', es: 'Gratuito' },
    { value: 2, en: 'With Cost', es: 'Con coste' }];

    courseCategoryList: any[] = [{ value: 1, en: 'Held at the College', es: 'Celebrado en el Colegio' },
    { value: 2, en: 'Held outside the College', es: 'Celebrado fuera del Colegio' },
    { value: 3, en: 'Online', es: 'On-Line' }];

    organizationCourseList: any[] = [{ value: 1, en: 'Organized by the College', es: 'Organizado por el Colegio' },
    { value: 2, en: 'Not organized by the College', es: 'No organizado por el Colegio' }];

    registrationStatusList: any[] = [{ value: 1, en: 'Confirmed', es: 'Confirmado' },
    { value: 2, en: 'Pending To Confirmed', es: 'Pendiente confirmación' }];

    paymentMethodList: any[] = [{ value: 1, en: 'Free', es: 'Gratuito' },
    { value: 2, en: 'Pos', es: 'POS' },
    { value: 3, en: 'Transfer', es: 'Transferencia' }];

    paymentStatusList: any[] = [{ value: 1, en: 'Paid Out', es: 'Pagado' },
    { value: 2, en: 'Outstanding', es: 'Pendiente de pago' }];

    activityApplyTypeList: any[] = [{ value: 1, en: 'All', es: 'Todo' },
    { value: 2, en: 'According to Situation', es: 'Deacuerdo a la situación' },
    { value: 3, en: 'Some', es: 'Agunos' }];

    activityDocumentTypeList: any[] = [{ value: 1, en: 'Not Generate', es: 'No generar' },
    { value: 2, en: 'Invoices', es: 'Facturas' },
    { value: 3, en: 'Receipts', es: 'Recíbos' }];

    activityPriceDetailTypeList: any[] = [{ value: 1, en: 'General Price List', es: 'Tarifa general' },
    { value: 2, en: 'New Price List', es: 'Nueva tarifa' }];

    documentTypeList: any[] = [ { value: 1, en: 'NIF', es: 'NIF' },
    { value: 2, en: 'NIE', es: 'NIE' },
    { value: 3, en: 'Passport', es: 'Pasaporte' }];

    doctorYesNoList: any[] = [ { value: false, en: 'No', es: 'No' },
    { value: true, en: 'Yes', es: 'Sí ' }];
}
