import {Directive, Input, OnDestroy, TemplateRef, ViewContainerRef} from '@angular/core';
import {Subscription} from 'rxjs';
import {PermissionCheckService} from "./permission-check.service";

@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective implements OnDestroy {
  @Input('appPermission') appCanAccess: string | string[];
  private permission$: Subscription;

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private permissionCheck: PermissionCheckService) {
  }

  ngOnDestroy(): void {
    this.permission$.unsubscribe();
  }

}
