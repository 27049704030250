import { Injectable } from '@angular/core';
import { DoctorCouncilEndPoints } from 'src/app/constants/api-endpoints';
import { Observable } from 'rxjs';
import { DoctorCouncil } from 'src/app/models/doctor/doctor-council.model';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/sections/common/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class DoctorCouncilService {

  doctorCouncilUrl = DoctorCouncilEndPoints;

  constructor(private _apiService : ApiService) { }

  private returnResponse(response){
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

  getDoctorCouncilByDoctorId(id: number): Observable<DoctorCouncil> {
    return this._apiService.get(this.doctorCouncilUrl.getDoctorCouncilByDoctorId + id, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getDoctorCouncilById(id: number): Observable<DoctorCouncil> {
    return this._apiService.get(this.doctorCouncilUrl.getDoctorCouncilById + id, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  GetCarouselData(): Observable<any> {
    return this._apiService.get(this.doctorCouncilUrl.getCarouselData, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveDoctorCouncil(doctorCouncil: DoctorCouncil): Observable<DoctorCouncil> {
    return this._apiService.post(this.doctorCouncilUrl.saveDoctorCouncil, doctorCouncil, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateDoctorCouncil(id: number, doctorCouncil: DoctorCouncil): Observable<DoctorCouncil> {
    return this._apiService.put(this.doctorCouncilUrl.updateDoctorCouncil + id, doctorCouncil, true, true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }
}
