import { Injectable } from '@angular/core';
import { CouncilResolutionEndPoints, CouncilResolutionDetailEndPoints, AdministrativeResourceEndPoints } from 'src/app/constants/api-endpoints';
import { DoctorCouncilResolution } from 'src/app/models/doctor/doctor-council-resolution.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CouncilResolutionDetail } from '../../../models/council-resolutions/council-resolution-detail';
import { AdministrativeResource } from '../../../models/council-resolutions/administrative-resource';
import { ApiService } from '../../common/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class CouncilResolutionService {

  councilResolutionEndPoints = CouncilResolutionEndPoints;
  councilResolutionDetailEndPoints = CouncilResolutionDetailEndPoints;
  administrativeResourceEndPoints = AdministrativeResourceEndPoints;



  constructor(private _apiService : ApiService) { }

  // Council Resolution APIs
  getCouncilResolutionById(id: number): Observable<DoctorCouncilResolution> {
    return this._apiService.get(this.councilResolutionEndPoints.getCouncilResolutionById + id, true).pipe(
    map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getCouncilResolutionList(): Observable<DoctorCouncilResolution> {
    return this._apiService.get(this.councilResolutionEndPoints.getCouncilResolutionList, true).pipe(
     map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveCouncilResolution(councilResolution: DoctorCouncilResolution): Observable<DoctorCouncilResolution> {
    return this._apiService.post(this.councilResolutionEndPoints.saveCouncilResolution, councilResolution, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateCouncilResolution(id: number, councilResolution: DoctorCouncilResolution): Observable<DoctorCouncilResolution> {
    return this._apiService.put(this.councilResolutionEndPoints.updateCouncilResolution + id, councilResolution, true, true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deleteCouncilResolution(id: number): Observable<DoctorCouncilResolution> {
    return this._apiService.delete(this.councilResolutionEndPoints.deleteCouncilResolution, id.toString(), true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  // Council Resolution Detail
  saveCouncilResolutionDetail(councilResolutionDetail: CouncilResolutionDetail): Observable<CouncilResolutionDetail> {
    return this._apiService.post(this.councilResolutionDetailEndPoints.saveCouncilResolutionDetail, councilResolutionDetail, true, true).pipe(
     map((response: any) => {
        return this.returnResponse(response);
      }));
  }


  updateCouncilResolutionDetail(id: number, councilResolutionDetail: CouncilResolutionDetail): Observable<CouncilResolutionDetail> {
    return this._apiService.put(this.councilResolutionDetailEndPoints.updateCouncilResolutionDetail + id, councilResolutionDetail, true, true).pipe(
     map((response: any) => {
        return this.returnResponse(response);
      }));

  }

  deleteCouncilResolutionDetail(id: number): Observable<CouncilResolutionDetail> {
    return this._apiService.delete(this.councilResolutionDetailEndPoints.deleteCouncilResolutionDetail, id.toString(), true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getCouncilResolutionDetailByResolutionId(id: number): Observable<CouncilResolutionDetail> {
    return this._apiService.get(this.councilResolutionDetailEndPoints.getCouncilResolutionDetailListByResolutionId + id, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }


  // Administrative Resource
  saveAdministrativeResource(administrativeResource: AdministrativeResource): Observable<AdministrativeResource> {
    return this._apiService.post(this.administrativeResourceEndPoints.saveAdministrativeResource, administrativeResource, true, true).pipe(
     map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateAdministrativeResource(id: number, administrativeResource: AdministrativeResource): Observable<AdministrativeResource> {
    return this._apiService.put(this.administrativeResourceEndPoints.updateAdministrativeResource + id, administrativeResource, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deleteAdministrativeResource(id: number): Observable<AdministrativeResource> {
    return this._apiService.delete(this.administrativeResourceEndPoints.deleteAdministrativeResource, id.toString(), true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getAdministrativeResourceByResolutionId(id: number): Observable<AdministrativeResource> {
    return this._apiService.get(this.administrativeResourceEndPoints.getAdministrativeResourceListByResolutionId + id, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  private returnResponse(response){
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

}
