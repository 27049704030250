import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent {
  @ViewChild('modalLarge', { static: false }) child;
  @Output() confirmEvent: EventEmitter<any> = new EventEmitter();
  @Output() cancelEvent: EventEmitter<any> = new EventEmitter();

  data: any;
  title: string;
  message: string;

  constructor(private translationService: TranslateService) {
  }

  public show(title, message, data = {}, messageKey?: string): void {
    setTimeout(() => {
      this.data = data;
      this.translate(title);
      if (messageKey) {
        this.translateData(messageKey);
      } else {
        this.message = message;
      }
      this.child.show();
    }, 25);
  }

  translate(key) {
    this.translationService.get([key]).subscribe((translations: any) => {
      this.title = translations[key];
    });
  }

  translateData(key) {
    this.translationService.get([key]).subscribe((translations: any) => {
      this.message = translations[key];
    });
  }

  confirm() {
    this.confirmEvent.emit(this.data);
    this.child.hide();
  }

  cancel() {
    this.cancelEvent.emit(this.data);
    this.child.hide();
  }

}
