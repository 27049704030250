import { CancelReceipt } from './../../../../models/administrative-documents/cancel-receipt.model';
import { Injectable } from '@angular/core';
import { CancelReceiptEndPoints } from 'src/app/constants/api-endpoints';
import { ApiService } from 'src/app/sections/common/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CancelReceiptService {

  receiptUrls = CancelReceiptEndPoints;

  constructor(private _apiService: ApiService) { }

  private returnResponse(response) {
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

  getReceiptById(id: number) {
    return this._apiService.get(this.receiptUrls.getCancelReceiptById.replace('{id}', id.toString()), true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveReceipt(receipt: CancelReceipt): Observable<CancelReceipt> {
    return this._apiService.post(this.receiptUrls.saveCancelReceipt, receipt, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }
}
