
export function SortList(list: any[], prop: string) {
    if (list != undefined && list != null && list.length > 0) {
        return list.sort((a, b) => (a[prop] < b[prop]) ? -1 : (a[prop] > b[prop]) ? 1 : 0);
    }
    return [];
}

export function SortEvents(events: any[]) {
    return events.sort((a, b) => {
        const dateA = new Date(a.eventDate);
        const dateB = new Date(b.eventDate);

        // Compare dates
        if (dateA > dateB) return -1;
        if (dateA < dateB) return 1;

        // If dates are the same, compare times
        const timeA = a.startTime ? parseTime(a.startTime) : null;
        const timeB = b.startTime ? parseTime(b.startTime) : null;

        if (timeA && timeB) {
            if (timeA > timeB) return 1;
            if (timeA < timeB) return -1;
        } else if (timeA) {
            return -1;
        } else if (timeB) {
            return 1;
        }
        return 0;
    });
}

function parseTime(timeString: string): Date {
    const [hours, minutes] = timeString.split(':').map(Number);
    const currentDate = new Date();
    currentDate.setHours(hours, minutes, 0, 0);
    return currentDate;
}