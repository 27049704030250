import { Injectable } from '@angular/core';
import {
  EventTypeEndPoints, ViaTypeEndPoints, CouncilEndPoints, CountryEndPoints, ExclusiveEndPoints, DedicationEndPoints,
  DoctorDegreeEndPoints, ProvinceEndPoints, PostEndPoints, DoctorTypeEndPoints,
  WorkingClinicEndPoints, MailAttentionEndPoints, TownEndPoints, WorkingSectorEndPoints, SocietyUrlEndPoints,
  RecallTypeEndPoints, NationalityEndPoints, GenderEndPoints, RecallCategoryEndPoints, CertificateEndPoints,
  JudicialResolutionIssueEndPoints, ProcedureEndPoints, IssuerEndPoints, SeriesEndPoints, ExpensesGroupEndPoints, PaymentTypeEndPoints, VatTableEndPoints, FiscalAccountTableEndPoints, ExpenseConceptTableEndPoints, ExpensesTypeTableEndPoints, CompanyLogoEndPoints, RecallFormOfContactEndPoints, CancelReasonEndPoints, PrescriptionStatusEndPoints
} from 'src/app/constants/api-endpoints';
import { EventType } from '../../../models/dashboard/event-type.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ViaType } from '../../../models/auxiliary/via-type.model';
import { Country } from '../../../models/country.model';
import { Council } from '../../../models/doctor/council.model';
import { RecallType } from '../../../models/dashboard/recall-type.model';
import { DoctorType } from '../../../models/doctor/doctorType.model';
import { MailAttention } from '../../../models/doctor/mail-attention.model';
import { Town } from '../../../models/doctor/doctor-town.model';
import { Societies } from '../../../models/doctor/societies.model';
import { Province } from '../../../models/doctor/province.model';
import { WorkingClinic } from '../../../models/doctor/working-clinic.model';
import { WorkingSector } from '../../../models/doctor/working-sector.model';
import { Exclusive } from '../../../models/doctor/exclusive.model';
import { Dedication } from '../../../models/doctor/dedication.model';
import { DoctorDegree } from '../../../models/doctor/doctor-degree.model';
import { Post } from '../../../models/doctor/post.model';
import { Nationality } from '../../../models/doctor/nationality.model';
import { DoctorGender } from '../../../models/doctor/doctor-gender.model';
import { RecallCategory } from '../../../models/auxiliary/recall-category.model';
import { Certificate } from '../../../models/auxiliary/certificate.model';
import { JudicialResolutionIssue } from '../../../models/auxiliary/judicial-resolution-issue';
import { Procedure } from '../../../models/auxiliary/procedure';
import { Issuer } from '../../../models/auxiliary/issuer.model';
import { Series } from '../../../models/auxiliary/series.model';
import { ExpensesGroup } from '../../../models/expenses/expenses-group';
import { PaymentType } from '../../../models/expenses/payment-type';
import { VatTypes } from 'src/app/models/auxiliary/vatypes';
import { FiscalAccounts } from 'src/app/models/auxiliary/fiscal-accounts.model';
import { ExpenseTypes } from 'src/app/models/auxiliary/expense-type.model';
import { ExpenseConcept } from 'src/app/models/auxiliary/expense-concept.model';
import { CompanyLogoConfiguration } from 'src/app/models/auxiliary/company-logo-configuration';
import { ApiService } from './api.service';
import { RecallFormOfContact } from 'src/app/models/dashboard/recall-form-of-contact.model';
import { TreasonForCancel } from 'src/app/models/auxiliary/treason-for-cancel.model';
import { PrescriptionStatus } from 'src/app/models/dashboard/prescription-status.model';

@Injectable({
  providedIn: 'root'
})
export class AuxillaryService {

  eventTypeUrls = EventTypeEndPoints;
  viaTypeUrls = ViaTypeEndPoints;
  countryUrl = CountryEndPoints;
  councilUrl = CouncilEndPoints;
  dedicationUrl = DedicationEndPoints;
  exclusiveUrl = ExclusiveEndPoints;
  degreeUrl = DoctorDegreeEndPoints;
  provinceUrl = ProvinceEndPoints;
  postUrl = PostEndPoints;
  doctorTypeUrl = DoctorTypeEndPoints;
  workingClinicUrl = WorkingClinicEndPoints;
  mailAttentionUrl = MailAttentionEndPoints;
  townUrl = TownEndPoints;
  workingSectorUrl = WorkingSectorEndPoints;
  societyUrl = SocietyUrlEndPoints;
  recallFormOfContactUrl = RecallFormOfContactEndPoints;
  cancelReasonUrl = CancelReasonEndPoints;
  recallTypeUrl = RecallTypeEndPoints;
  nationalityUrl = NationalityEndPoints;
  genderUrl = GenderEndPoints;
  recallCategoryUrls = RecallCategoryEndPoints;
  certificateUrls = CertificateEndPoints;
  judicialResolutionIssueUrls = JudicialResolutionIssueEndPoints;
  procedureUrls = ProcedureEndPoints;
  issuerUrls = IssuerEndPoints;
  seriesUrls = SeriesEndPoints;
  expensesGroupUrls = ExpensesGroupEndPoints;
  paymentTypeUrls = PaymentTypeEndPoints;
  vatTypeUrls = VatTableEndPoints;
  fiscalAccountUrls = FiscalAccountTableEndPoints;
  expenseTypeUrls = ExpensesTypeTableEndPoints;
  expenseConceptUrls = ExpenseConceptTableEndPoints;
  companyLogoUrls = CompanyLogoEndPoints;
  prescriptionStatusUrls = PrescriptionStatusEndPoints;

  constructor(private _apiService : ApiService) { }

  private returnResponse(response) {
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

  getEventTypeList(): Observable<EventType> {
    return this._apiService.get(this.eventTypeUrls.getEventTypeList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getViaTypeList(): Observable<ViaType> {
    return this._apiService.get(this.viaTypeUrls.getViaTypeList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getCouncilList(): Observable<Council> {
    return this._apiService.get(this.councilUrl.getCouncilList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getCertificateList(): Observable<Certificate> {
    return this._apiService.get(this.certificateUrls.getCertificateList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getCountryList(): Observable<Country> {
    return this._apiService.get(this.countryUrl.getCountryList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getRecallTypeList(): Observable<RecallType> {
    return this._apiService.get(this.recallTypeUrl.getRecallTypeList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getRecallFormOfContactList(isActiveOnly:boolean = false): Observable<RecallFormOfContact> {
    return this._apiService.get(this.recallFormOfContactUrl.getRecallFormOfContactList + "?activeOnly=" + isActiveOnly, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getCancelReasonList(isActiveOnly:boolean = false): Observable<TreasonForCancel> {
    return this._apiService.get(this.cancelReasonUrl.getCancelReasonList + "?activeOnly=" + isActiveOnly, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getRecallCategoryList(): Observable<RecallCategory> {
    return this._apiService.get(this.recallCategoryUrls.getRecallCategoryList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getDoctorTypeList(): Observable<DoctorType> {
    return this._apiService.get(this.doctorTypeUrl.getDoctorTypeList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getMailAttentionList(): Observable<MailAttention> {
    return this._apiService.get(this.mailAttentionUrl.getMailAttentionList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getTownList(): Observable<Town> {
    return this._apiService.get(this.townUrl.getTownList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getSocietiesList(): Observable<Societies> {
    return this._apiService.get(this.societyUrl.getDoctorSociety, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getProvinceList(): Observable<Province> {
    return this._apiService.get(this.provinceUrl.getProvinceList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getProvinceListForStatistics(): Observable<Province> {
    return this._apiService.get(this.provinceUrl.getProvinceListForStatistics, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getWorkingClinicList(): Observable<WorkingClinic> {
    return this._apiService.get(this.workingClinicUrl.getWorkingClinicList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getWorkingSectorList(): Observable<WorkingSector> {
    return this._apiService.get(this.workingSectorUrl.getWorkingSectorList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getExclusiveList(): Observable<Exclusive> {
    return this._apiService.get(this.exclusiveUrl.getExclusiveList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getDedicationList(): Observable<Dedication> {
    return this._apiService.get(this.dedicationUrl.getDedicationList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getDegreeList(): Observable<DoctorDegree> {
    return this._apiService.get(this.degreeUrl.getDoctorDegreeList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getPostList(): Observable<Post> {
    return this._apiService.get(this.postUrl.getPostList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getNationalityList(): Observable<Nationality> {
    return this._apiService.get(this.nationalityUrl.getNationalityList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getGenderList(): Observable<DoctorGender> {
    return this._apiService.get(this.genderUrl.getGenderList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getJudicialResolutionIssueList(): Observable<JudicialResolutionIssue> {
    return this._apiService.get(this.judicialResolutionIssueUrls.getJudicialResolutionIssueList, true).pipe(
     map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getProcedureList(): Observable<Procedure> {
    return this._apiService.get(this.procedureUrls.getProcedureList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getIssuerList(): Observable<Issuer> {
    return this._apiService.get(this.issuerUrls.getIssuerList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getSeriesList(): Observable<Series> {
    return this._apiService.get(this.seriesUrls.getSeriesList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getVatList(isActiveOnly:boolean = false): Observable<VatTypes> {
    return this._apiService.get(this.vatTypeUrls.getVatTableList + "?activeOnly=" + isActiveOnly, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }


  saveVatType(vatType: VatTypes): Observable<VatTypes> {
    return this._apiService.post(this.vatTypeUrls.saveVatTable, vatType, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }


  updateAsDefault(id: number, vatType: VatTypes): Observable<VatTypes> {
    return this._apiService.put(this.vatTypeUrls.updateAsDefault + '/'+ id, vatType, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }


  updateVatType(id: number, vatType: VatTypes): Observable<VatTypes> {
    return this._apiService.put(this.vatTypeUrls.updateVatTable + id, vatType, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deleteVatType(id: number): Observable<VatTypes> {
    return this._apiService.delete(this.vatTypeUrls.deleteVatTable, id.toString(), true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }


  //Fiscal account//

  getFiscalAccountsList(): Observable<FiscalAccounts> {
    return this._apiService.get(this.fiscalAccountUrls.getFiscalAccountTableList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveFiscalAccount(fiscalAccount: FiscalAccounts): Observable<FiscalAccounts> {
    return this._apiService.post(this.fiscalAccountUrls.saveFiscalAccountTable, fiscalAccount, true, true).pipe(
        map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateFiscalAccount(id: number, fiscalAccount: FiscalAccounts): Observable<FiscalAccounts> {
    return this._apiService.put(this.fiscalAccountUrls.updateFiscalAccountTable + id, fiscalAccount, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deleteFiscalAccount(id: number): Observable<FiscalAccounts> {
    return this._apiService.delete(this.fiscalAccountUrls.deleteFiscalAccountTable, id.toString(), true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  //Expense type//

  getExpenseTypesList(): Observable<ExpenseTypes> {
    return this._apiService.get(this.expenseTypeUrls.getExpenseTypeTableList, true).pipe(
    map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveExpenseTypes(expenseType: ExpenseTypes): Observable<ExpenseTypes> {
    return this._apiService.post(this.expenseTypeUrls.saveExpenseTypeTable, expenseType, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateExpenseTypes(id: number, expenseType: ExpenseTypes): Observable<ExpenseTypes> {
    return this._apiService.put(this.expenseTypeUrls.updateExpenseTypeTable + id, expenseType, true, true).pipe(
     map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deleteExpenseTypes(id: number): Observable<ExpenseTypes> {
    return this._apiService.delete(this.expenseTypeUrls.deleteExpenseTypeTable, id.toString(), true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }


  //Expense Concept//

  getExpenseConceptList(): Observable<ExpenseConcept> {
    return this._apiService.get(this.expenseConceptUrls.getExpenseConceptTableList, true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveExpenseConcept(expenseType: ExpenseConcept): Observable<ExpenseConcept> {
    return this._apiService.post(this.expenseConceptUrls.saveExpenseConceptTable, expenseType, true, true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateExpenseConcept(id: number, expenseType: ExpenseConcept): Observable<ExpenseConcept> {
    return this._apiService.put(this.expenseConceptUrls.updateExpenseConceptTable + id, expenseType, true, true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deleteExpenseConcept(id: number): Observable<ExpenseConcept> {
    return this._apiService.delete(this.expenseConceptUrls.deleteExpenseConceptTable, id.toString(), true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  //Expense Group//

  getExpensesGroupList(): Observable<ExpensesGroup> {
    return this._apiService.get(this.expensesGroupUrls.getExpensesGroupList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveExpensesGroup(expenseType: ExpensesGroup): Observable<ExpensesGroup> {
    return this._apiService.post(this.expensesGroupUrls.saveExpensesGroup, expenseType, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateExpensesGroup(id: number, expenseType: ExpensesGroup): Observable<ExpensesGroup> {
    return this._apiService.put(this.expensesGroupUrls.updateExpensesGroup + id, expenseType, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deleteExpensesGroup(id: number): Observable<ExpensesGroup> {
    return this._apiService.delete(this.expensesGroupUrls.deleteExpensesGroup, id.toString(), true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }


   //Payment types//

  getPayementTypeList(): Observable<PaymentType> {
    return this._apiService.get(this.paymentTypeUrls.getPaymentTypeList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }



  savePaymentType(paymentType: PaymentType): Observable<PaymentType> {
    return this._apiService.post(this.paymentTypeUrls.savePaymentType, paymentType, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updatePaymentType(id: number, fiscalAccount: PaymentType): Observable<PaymentType> {
    return this._apiService.put(this.paymentTypeUrls.updatePaymentType + id, fiscalAccount, true, true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deletePaymentType(id: number): Observable<PaymentType> {
    return this._apiService.delete(this.paymentTypeUrls.deletePaymentType, id.toString(), true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  //add company logo

  getCompanyLogoList(): Observable<CompanyLogoConfiguration> {
    return this._apiService.get(this.companyLogoUrls.getCompanyLogoList, true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveCompanyLogo(companyLogoConfiguration: CompanyLogoConfiguration): Observable<CompanyLogoConfiguration> {
    return this._apiService.post(this.companyLogoUrls.saveCompanyLogo, companyLogoConfiguration, true, true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateCompanyLogo(id: number, companyLogoConfiguration: CompanyLogoConfiguration): Observable<CompanyLogoConfiguration> {
    return this._apiService.put(this.companyLogoUrls.updateCompanyLogo + id, companyLogoConfiguration, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deleteCompanyLogo(id: number): Observable<CompanyLogoConfiguration> {
    return this._apiService.delete(this.companyLogoUrls.deleteCompanyLogo, id.toString(), true).pipe(
        map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  // Prescription Status
  getPrescriptionStatusList(): Observable<PrescriptionStatus> {
    return this._apiService.get(this.prescriptionStatusUrls.getPrescriptionStatusList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }
}
