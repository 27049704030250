import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
//import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NovaSearchModule } from 'nova-search';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthorizationInterceptor } from 'src/app/interceptor/authorization.interceptor';
import { NgbDateFormatOutput } from '../common/ngb-date-format-output';
import { DateParserFormatter } from '../common/date-parser-formater';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NgbModule,
        NgSelectModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        SharedModule,
        TranslateModule,
        ToastrModule,
        FormsModule,
        SelectDropDownModule,
        NovaSearchModule,
        // AutocompleteLibModule
    ],
    providers: [{
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationInterceptor,
            multi: true
        }, { provide: NgbDateAdapter, useClass: NgbDateFormatOutput },
        { provide: NgbDateParserFormatter, useClass: DateParserFormatter }]
})
export class AdministrativeDocumentsModuleModule { }
