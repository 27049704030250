import { CorrespondenceDocument } from './../../../models/correspondence/correspondence-document.model';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CorrespondencePoints, UploadFileDataEndPoints } from '../../../constants/api-endpoints';
import { Correspondence } from '../../../models/correspondence/correspondence.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../common/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class CorrespondenceService {

  correspondenceUrls = CorrespondencePoints;
  uploadFileDataUrls = UploadFileDataEndPoints;

  constructor(private _apiService : ApiService) { }

  private returnResponse(response){
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

  getCorrespondenceById(id: number): Observable<Correspondence> {
    return this._apiService.get(this.correspondenceUrls.getCorrespondenceById + id, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getCorrespondenceList(): Observable<Correspondence> {
    return this._apiService.get(this.correspondenceUrls.getCorrespondenceList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getCorrespondenceListPaginated(pageSize: number, page: number, isEntry: boolean, fromDate: string, toDate: string): Observable<Correspondence> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('filter', (isEntry ? "1" : "0") + ',' + fromDate  + ',' + toDate);

      const query = params.toString().replace('+', '%20');

    return this._apiService.get(this.correspondenceUrls.getcorrespondencesListPaginated + '?' + `${query}`, true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  correspondenceListExport(pageSize: number, page: number, isEntry: boolean, fromDate: string, toDate: string): Observable<Correspondence> {
    
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('filter', (isEntry ? "1" : "0") + ',' + fromDate  + ',' + toDate);

      const query = params.toString().replace('+', '%20');

    return this._apiService.get(this.correspondenceUrls.correspondenceListExport + '?' + `${query}`, true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveCorrespondence(correspondence: Correspondence): Observable<Correspondence> {
    return this._apiService.post(this.correspondenceUrls.saveCorrespondence, correspondence, true, true).pipe(
    map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateCorrespondence(id: number, correspondence: Correspondence): Observable<Correspondence> {
    return this._apiService.put(this.correspondenceUrls.updateCorrespondence + id, correspondence, true, true).pipe(
    map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deleteCorrespondence(id: number): Observable<Correspondence> {
    return this._apiService.delete(this.correspondenceUrls.deleteCorrespondence, id.toString(), true).pipe(
   map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  uploadDocument(formData: FormData) {
    return this._apiService.upload(this.uploadFileDataUrls.uploadDocument, formData, true).pipe(
      map((response: any) => {
        return this.onSuccess(response);
      }));
  }

  saveDocuments(correspondenceDocuments: CorrespondenceDocument[]){
    return this._apiService.post(this.correspondenceUrls.saveCorrespondenceDocumentRange, correspondenceDocuments, true,true).pipe(
      map((response: any) => {
        return this.onSuccess(response);
      }));
  }

  deleteDocuments(correspondenceDocuments: number[]){
    return this._apiService.post(this.correspondenceUrls.deleteCorrespondenceDocuments, correspondenceDocuments, true,true).pipe(
      map((response: any) => {
        return this.onSuccess(response);
      }));
  }

  onSuccess(response){
    if (response.status > 399) {
      throw (response);
    }
    return response;
  }
}
