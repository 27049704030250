import { SocietySearchPath, CourseSearchPath, CouncilResolutionSearchPath, JudicialResolutionSearchPath } from './../../constants/search-path';
import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalSearchEndPoints } from 'src/app/constants/api-endpoints';
import { UserSearchPath, DoctorSearchPath, ClinicSearchPath } from 'src/app/constants/search-path';
import { Doctor } from '../../models/doctor/doctor.model';
import { CorrespondenceService } from 'src/app/sections/correspondence/services/correspondence.service';
import { Correspondence } from 'src/app/models/correspondence/correspondence.model';
import { HeaderService } from './services/header.service';
import { IHeaderState } from './services/interfaces/header.interface';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/sections/auth/services/auth.service';
import { User } from 'src/app/models/user.model';
import { CommonUIService } from 'src/app/sections/common/services/common.ui.service';
import { ApiService } from 'src/app/sections/common/services/api.service';
import * as moment from 'moment';
declare var require: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() headerTitle: string;
  @Output() getlocale: EventEmitter<any> = new EventEmitter();
  doctorGeneralDetails: Doctor;
  entryCorrespondenceList: Correspondence[];
  sentCorrespondenceList: Correspondence[];
  user: User;
  receiveCount = 0;
  sentCount = 0;
  isAddClassValue = false;

  private subscription: Subscription;
  public url = GlobalSearchEndPoints.getGlobalSearchData;
  public search = '';
  public api = 'http';

  appVersion = "0.0.0"

  constructor(private router: Router, private correspondenceService: CorrespondenceService, private headerService: HeaderService,
    public _commonUIService: CommonUIService, private authService: AuthService, private _apiServcie: ApiService) {
    this.entryCorrespondenceList = [] as Correspondence[];
    this.sentCorrespondenceList = [] as Correspondence[];
    this.user = new User();
    const userDetails = this.authService.getTokenData();
    this.setUrlForGlobalSearch();
    if (userDetails) {
      this.user.username = userDetails.userName;
    }
    this.subscription = this._commonUIService.classState
      .subscribe((data: any) => {
        this.addClass(data);
      });

    this.subscription = this.headerService.headerCorrespondenceUpdateState
      .subscribe((state: IHeaderState) => {
        if (state.updateCorrespondence) {
          this.getCorrespondenceList();
        }
      });
  }

  ngOnInit() {
    if (require('../../../../package.json') && require('../../../../package.json') != undefined) {
      this.appVersion = require('../../../../package.json').version;
    }
    this.getCorrespondenceList();
  }

  setUrlForGlobalSearch() {
    this.url = this._apiServcie.BASE_URL + this.url;
  }

  addClass(classvlaue: boolean) {
    this.isAddClassValue = classvlaue;
  }

  get getTitle() {
    const data = this.router.url.split('/');
    data.splice(0, 1);
    const routerArray = [];
    for (let i = 0; i < data.length; i++) {
      const tempData = {
        routePath: (data[i - 1] ? data[i - 1]['routePath'] : '') + '/' + data[i],
        title: data[i]
      };
      routerArray.push(tempData);
    }
    return routerArray;
  }

  // Correspondence
  public getCorrespondenceList() {
    this.correspondenceService.getCorrespondenceList().subscribe(
      (data: any) => {
        if (data._data != null) {
          const correspondenceList = data._data;
          const today = moment(new Date()).format('YYYY-MM-DD');
          correspondenceList.forEach(element => {
            element.datestring = moment(element.date).format('YYYY-MM-DD');
          });
          this.entryCorrespondenceList = correspondenceList.filter(c => c.isEntry && c.datestring == today);
          this.receiveCount = this.entryCorrespondenceList.length;
          this.sentCorrespondenceList = correspondenceList.filter(c => !c.isEntry && c.datestring == today);
          this.sentCount = this.sentCorrespondenceList.length;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  // Intelligent Search
  handleResultSelected(result) {
    if (result) {
      this.search = result.value;
      if (result.entity && result.value) {
        if (result.entity == UserSearchPath.module) {
          this.router.navigate([UserSearchPath.path]);
        } else if (result.entity == DoctorSearchPath.module) {
          this.router.navigate([DoctorSearchPath.path + result.referenceId]);
        } else if (result.entity == ClinicSearchPath.module) {
          this.router.navigate([ClinicSearchPath.path + result.referenceId]);
        } else if (result.entity == SocietySearchPath.module) {
          this.router.navigate([SocietySearchPath.path], { queryParams: { id: result.referenceId } });
        } else if (result.entity == CourseSearchPath.module) {
          this.router.navigate([CourseSearchPath.path], { queryParams: { id: result.referenceId } });
        } else if (result.entity == CouncilResolutionSearchPath.module) {
          this.router.navigate([CouncilResolutionSearchPath.path + result.referenceId]);
        } else if (result.entity == JudicialResolutionSearchPath.module) {
          this.router.navigate([JudicialResolutionSearchPath.path + result.referenceId]);
        }
      } else {
        console.log('result.entity or result.value null');
      }
    }
  }

  getLocaleId(prefix: string) {
    this.getlocale.emit(prefix);
  }

  ShowExpandView() {
    this._commonUIService.isActiveBothPanel = false;
    this._commonUIService.isShowExpandView = true;
  }

  ShowBothPanels() {
    this._commonUIService.isActiveBothPanel = true;
    this._commonUIService.isShowExpandView = false;
  }

}
