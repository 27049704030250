import { DropdownService } from 'src/app/sections/common/services/dropdown.service';
import { Delay } from 'src/app/sections/common/delay-helper';
import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Correspondence } from '../../../../models/correspondence/correspondence.model';
import { CorrespondenceService } from '../../services/correspondence.service';

import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../../sections/auth/services/auth.service';
import { ViaType } from '../../../../models/auxiliary/via-type.model';
import { AuxillaryService } from '../../../../sections/common/services/auxillary.service';
import { HeaderService } from '../../../../shared/header/services/header.service';
import { changeNgbDateToCustomFormat, toNgbDate } from '../../../../sections/common/services/change-ngb-date-to-custom-format';
import { CorrespondenceDocument } from 'src/app/models/correspondence/correspondence-document.model';
import { UploadFileDataService } from 'src/app/sections/common/services/upload-file-data.service';
import { HttpEventType } from '@angular/common/http';
import { CommonUIService } from 'src/app/sections/common/services/common.ui.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-entry-correspondence',
  templateUrl: './add-entry-correspondence.component.html',
  styleUrls: ['./add-entry-correspondence.component.scss']
})
export class AddEntryCorrespondenceComponent implements OnInit {
  @ViewChild('modalLargeNew', { static: false }) child;
  @Output() close: EventEmitter<any> = new EventEmitter();

  submitted = false;
  correspondence: Correspondence;
  correspondenceForm: FormGroup;
  loggedUserId = 0;
  viaTypeList: ViaType[];
  isEdit: boolean;
  isLoading = false;
  newFilesList = [];
  deletedFilesList = [];
  isDocumentsOnly = false;

  validationMessages = {  }

  constructor(private formBuilder: UntypedFormBuilder, private correspondenceService: CorrespondenceService, private toastService: ToastrService,
    private translationService: TranslateService, private authService: AuthService, private auxillaryService: AuxillaryService,
    private headerService: HeaderService, private uploadFileDataService: UploadFileDataService,
    private commonUiService: CommonUIService, private dropdownService: DropdownService) {
    this.correspondence = new Correspondence();
    this.viaTypeList = [] as ViaType[];
    this.initializeForAddEntryCorrespondence();
    const userDetails = this.authService.getTokenData();
    if (userDetails) {
      this.loggedUserId = userDetails.userId;
    }
  }

  ngOnInit() {
    this.getViaTypeList();
    this.checkIfEditOrAdd();
  }

  public show(correspondenceData, isDocumentsOnly: boolean = false): void {
    this.isDocumentsOnly = isDocumentsOnly;
    this.child.show();
    this.submitted = false;
    this.newFilesList = [];
    this.deletedFilesList = [];
    if (correspondenceData) {
      this.correspondence = correspondenceData;
    }
    this.correspondenceForm.reset();
    this.checkIfEditOrAdd();
  }

  getViaTypeList() {
    this.dropdownService.getViaTypeList().subscribe(
      (data: any) => {
        if (data._data != null) {
          this.viaTypeList = data._data;
        }
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  checkIfEditOrAdd() {
    if (!this.correspondence.id) {
      this.isEdit = false;
      this.initializeForAddEntryCorrespondence();
      return;
    }
    this.isEdit = true;
    this.initializeForEditEntryCorrespondence();
  }

  initializeForAddEntryCorrespondence() {
    this.correspondenceForm = this.formBuilder.group({
      id: [''],
      code: ['', [Validators.required]],
      registeredDate: [null, [Validators.required]],
      date: [null],
      origin: [''],
      description: [''],
      viaTypeId: [0]
    });
  }

  initializeForEditEntryCorrespondence() {
    const date = this.correspondence.date ? toNgbDate(new Date(this.correspondence.date)) : null;
    const registeredDate = toNgbDate(new Date(this.correspondence.registeredDate));
    this.correspondenceForm = this.formBuilder.group({
      id: [this.correspondence.id],
      registeredDate: [registeredDate, [Validators.required]],
      date: [date],
      code: [this.correspondence.code, [Validators.required]],
      origin: [this.correspondence.origin],
      description: [this.correspondence.description],
      viaTypeId: [this.correspondence.viaTypeId]
    });
  }

  onFileChange(event: any) {
    if (event) {
      const files: any = event.target.files;
      if (this.newFilesList.find(d => d.name == files[0].name) || this.correspondence.correspondenceDocument.find(d => d.fileName == files[0].name))
        this.failToast(this.commonUiService.Translate('common.documentExists'));
      else
        this.newFilesList.push(files[0])
      event.target.value = ""
    }
  }

  handleDeleteNewFile(file) {
    this.newFilesList = this.newFilesList.filter(f => f.name != file.name).slice();
  }

  handleDeleteExistingFile(file) {
    this.deletedFilesList.push(file.id);
    this.correspondence.correspondenceDocument = this.correspondence.correspondenceDocument.filter(f => f.id != file.id).slice()
  }

  onSave() {
    this.submitted = true;
    if (this.correspondenceForm.controls.viaTypeId.value == 0) {
      this.correspondenceForm.controls.viaTypeId.setValue(null);
    }
    if (!this.commonUiService.validateForm(this.correspondenceForm, this.validationMessages)) {
      return;
    }
    this.correspondence = this.correspondenceForm.value;
    this.correspondence.date = this.correspondenceForm.value.date ? changeNgbDateToCustomFormat(this.correspondenceForm.value.date) : null;
    this.correspondence.registeredDate = changeNgbDateToCustomFormat(this.correspondenceForm.value.registeredDate);
    if (this.isEdit) {
      this.updateCorrespondence(this.correspondence);
      return;
    }
    this.saveCorrespondence(this.correspondence);
  }

  private async syncDocuments(correspondenceId: number) {
    if (this.newFilesList.length) {
      let newCorrespondenceDocuments = this.newFilesList.map(document => {
        const correspondenceDocument = new CorrespondenceDocument();
        correspondenceDocument.fileName = document.name;
        correspondenceDocument.correspondenceId = correspondenceId;
        return correspondenceDocument;
      });
      this.correspondenceService.saveDocuments(newCorrespondenceDocuments).subscribe(
        (data: any) => {
          newCorrespondenceDocuments = data._data;
          this.newFilesList.forEach(document => {
            const correspondenceDocument = newCorrespondenceDocuments.find(d => d.fileName == document.name);
            if (correspondenceDocument) {
              const formData: FormData = new FormData();
              formData.append(document.fileName, document, correspondenceDocument.generateFileName);
              formData.append('folderName', 'Correspondence');
              formData.append('referenceId', correspondenceId.toString());
              formData.append('id', correspondenceDocument.id.toString());

              this.correspondenceService.uploadDocument(formData)
                .subscribe(
                  res => {
                    if (res.type == HttpEventType.Response) {
                      this.newFilesList = this.newFilesList.filter(file => file.fileName != document.fileName);
                    }
                  },
                  (error) => {
                    this.failToast(error._message);
                  }
                );
            }
          });
        },
        (error) => {
          this.failToast(error._message);
        }
      );
    }
    if (this.deletedFilesList.length) {
      this.correspondenceService.deleteDocuments(this.deletedFilesList).subscribe(
        (data: any) => {
          this.deletedFilesList = [];
        },
        (error) => {
          this.failToast(error._message);
        }
      );
    }
    await Delay(500);
    this.returnResponse();
  }

  private returnResponse() {
    this.close.emit();
    this.child.hide();
    this.isLoading = false;
    this.headerService.update();
  }

  saveCorrespondence(correspondence: Correspondence) {
    correspondence.id = 0;
    correspondence.createdDate = new Date();
    correspondence.createdBy = this.loggedUserId;
    correspondence.status = true;
    correspondence.isEntry = true;
    this.isLoading = true;
    this.correspondenceService.saveCorrespondence(correspondence).subscribe(
      (data: any) => {
        this.successToast('saveEntryCorrespondence');
        this.syncDocuments(data._data.id);
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  updateCorrespondence(correspondence: Correspondence) {
    correspondence.id = this.correspondence.id;
    correspondence.status = true;
    correspondence.modifiedDate = new Date();
    correspondence.modifiedBy = this.loggedUserId;
    correspondence.isEntry = true;
    correspondence.createdBy = this.correspondence.createdBy;
    correspondence.createdDate = this.correspondence.createdDate;
    this.isLoading = true;
    this.correspondenceService.updateCorrespondence(correspondence.id, correspondence).subscribe(
      (data: any) => {
        this.successToast('updateEntryCorrespondence');
        this.syncDocuments(data._data.id);
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  successToast(messageTranslationKey) {
    this.translationService.get(['success', messageTranslationKey]).subscribe((translations: any) => {
      this.toastService.success(translations[messageTranslationKey], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }

  failToast(message) {
    this.translationService.get(['errorTitle', message]).subscribe((translations: any) => {
      this.toastService.error(translations[message], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }

  get viaTypeId() {
    return this.correspondenceForm.get('viaTypeId');
  }

  get code() {
    return this.correspondenceForm.get('code');
  }

  get registeredDate() {
    return this.correspondenceForm.get('registeredDate');
  }

  get date() {
    return this.correspondenceForm.get('date');
  }

  get origin() {
    return this.correspondenceForm.get('origin');
  }

  get description() {
    return this.correspondenceForm.get('description');
  }
}
