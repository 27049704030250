import { Injectable } from '@angular/core';
import { ReceiptEndPoints } from 'src/app/constants/api-endpoints';
import { Receipt } from 'src/app/models/administrative-documents/receipt.model';
import { ApiService } from 'src/app/sections/common/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReceiptService {

  receiptUrls = ReceiptEndPoints;

  constructor(private _apiService: ApiService) { }

  private returnResponse(response) {
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

  getReceiptById(id: number) {
    return this._apiService.get(this.receiptUrls.getReceiptById.replace('{id}', id.toString()), true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveReceipt(payment: Receipt[]): Observable<Receipt> {
    return this._apiService.post(this.receiptUrls.saveReceipt, payment, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getReceiptListPagination(pageSize: number, page: number, filters: any) {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('filter', (filters.searchText || '') + ';' + (filters.fromDate || '') + ";" + (filters.toDate || '') + ";" + filters.originId);
    const query = params.toString().replace('+', '%20');
    return this._apiService.get(this.receiptUrls.receiptListPagination + '?' + `${query}`, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }
}
