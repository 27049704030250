import { PrescriptionStatus } from './prescription-status.model';

export class Prescription {
    prescriptionDate: Date;
    doctorId: number;
    entryDate: Date;
    modifiedDate: Date;
    enteredBy: number;
    modifiedBy: number;
    doctorName: string;
    id: number;
    prescriptionStatusId: number;
    prescriptionStatus: PrescriptionStatus;

    constructor() {
        this.prescriptionStatus = new PrescriptionStatus();
    }
}