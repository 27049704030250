import { Injectable } from '@angular/core';
import { InvoiceEndPoints } from 'src/app/constants/api-endpoints';
import { Invoice } from 'src/app/models/administrative-documents/invoice.model';
import { ApiService } from 'src/app/sections/common/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  invoiceUrls = InvoiceEndPoints;

  constructor(private _apiService: ApiService) { }

  private returnResponse(response) {
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

  getInvoiceById(id: number) {
    return this._apiService.get(this.invoiceUrls.getInvoiceById.replace('{id}', id.toString()), true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveInvoice(invoice: Invoice[]): Observable<Invoice> {
    return this._apiService.post(this.invoiceUrls.saveInvoice, invoice, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getInvoiceListPagination(pageSize: number, page: number, filters: any) {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('filter', (filters.searchText || '') + ';' + (filters.fromDate || '') + ";" + (filters.toDate || '') + ";" + filters.originId);
    const query = params.toString().replace('+', '%20');
    return this._apiService.get(this.invoiceUrls.invoiceListPagination + '?' + `${query}`, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }
}
