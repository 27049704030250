import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { DoctorCouncilResolution } from '../../../../app/models/doctor/doctor-council-resolution.model';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CouncilResolutionService } from '../services/council-resolution.service';

import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../auth/services/auth.service';
import { AuxillaryService } from '../../common/services/auxillary.service';
import { Doctor } from '../../../../app/models/doctor/doctor.model';
import { DoctorService } from '../../doctor/services/doctor.service';
import { ToastrService } from 'ngx-toastr';
import { CommonUIService } from '../../common/services/common.ui.service';

@Component({
  selector: 'app-add-council-resolutions',
  templateUrl: './add-council-resolutions.component.html',
  styleUrls: ['./add-council-resolutions.component.scss']
})
export class AddCouncilResolutionsComponent implements OnInit {


  @ViewChild('modalLargeNew', { static: false }) child;

  @Output() close: EventEmitter<any> = new EventEmitter();
  councilResolution: DoctorCouncilResolution;
  public search = '';
  doctorList: Doctor[];
  submitted = false;
  councilResolutionForm: FormGroup;
  isEdit: boolean;
  isLoading = false;
  public searchIssue = '';
  loggedUserId = 0;
  doctorId = 0;

  validationMessages = {}

  constructor(private formBuilder: UntypedFormBuilder, private councilResolutionsService: CouncilResolutionService,
    private toastService: ToastrService, private translationService: TranslateService, private authService: AuthService,
    private auxillaryService: AuxillaryService, private doctorService: DoctorService, private commonUiService: CommonUIService) {
    this.councilResolution = new DoctorCouncilResolution();
    this.doctorList = [] as Doctor[];
    const userDetails = this.authService.getTokenData();
    if (userDetails) {
      this.loggedUserId = userDetails.userId;
    }
  }

  ngOnInit() {
    this.checkIfEditOrAdd();
    this.getDoctorList();
  }

  public show(councilResolutionData): void {
    this.submitted = false;
    this.councilResolution = new DoctorCouncilResolution();
    if (councilResolutionData) {
      this.councilResolution = councilResolutionData;
    }
    this.councilResolutionForm.reset();
    this.checkIfEditOrAdd();
    this.child.show();
  }


  checkIfEditOrAdd() {
    if (!this.councilResolution.id) {
      this.isEdit = false;
      this.initializeForAddCouncilResolution();
      return;
    }
    this.isEdit = true;
    this.doctorId = this.councilResolution.doctorId;
    this.search = this.councilResolution.doctorName;
    this.initializeForEditCouncilResolution();
  }

  getDoctorList() {
    this.doctorService.getDoctorList().subscribe(
      (data: any) => {
        if (data._data != null) {
          this.doctorList = data._data;
        }
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  initializeForAddCouncilResolution() {
    this.councilResolutionForm = this.formBuilder.group({
      id: [''],
      resolutionsType: [''],
      topic: [''],
      doctorId: ['', [Validators.required]],
      collegiateNumber: [''],
      part1: [''],
      part2: [''],
      part3: [''],
      part4: [''],
      observations: [''],
      isLegalAppeal: [''],
      councilResolutionStatusId: [0],
    });
  }

  initializeForEditCouncilResolution() {
    this.councilResolutionForm = this.formBuilder.group({
      id: [this.councilResolution.id],
      resolutionsType: [this.councilResolution.resolutionsType],
      topic: [this.councilResolution.topic],
      doctorId: [this.search, [Validators.required]],
      collegiateNumber: [this.councilResolution.collegiateNumber],
      part1: [this.councilResolution.part1],
      part2: [this.councilResolution.part2],
      part3: [this.councilResolution.part3],
      part4: [this.councilResolution.part4],
      observations: [this.councilResolution.observations],
      isLegalAppeal: [this.councilResolution.isLegalAppeal],
      councilResolutionStatusId: [this.councilResolution.councilResolutionStatusId],
    });
  }



  onSave() {
    this.submitted = true;

    if (this.councilResolutionForm.controls.councilResolutionStatusId.value === 0) {
      this.councilResolutionForm.controls.councilResolutionStatusId.setValue(null);
    }
    if (!this.commonUiService.validateForm(this.councilResolutionForm, this.validationMessages)) {
      return;
    }
    if (this.isEdit) {
      this.updateCouncilResolutions(this.councilResolutionForm.value);
      return;
    }
    this.saveCouncilResolution(this.councilResolutionForm.value);
  }

  saveCouncilResolution(councilResolution: DoctorCouncilResolution) {
    this.isLoading = true;
    councilResolution.id = 0;
    councilResolution.createdDate = new Date();
    councilResolution.createdBy = this.loggedUserId;
    councilResolution.doctorId = this.doctorId;
    councilResolution.councilResolutionStatusId = councilResolution.councilResolutionStatusId == 0 ? null : councilResolution.councilResolutionStatusId;
    this.councilResolutionsService.saveCouncilResolution(councilResolution).subscribe(
      (data: any) => {
        this.successToast('saveCouncilResolution');
        this.returnResponse(data._data);
      },
      (error) => {
        this.failToast(error._message);
        this.isLoading = false;
      }
    );
  }

  private returnResponse(response: any) {
    this.close.emit(response);
    this.child.hide();
    this.isLoading = false;
  }

  updateCouncilResolutions(councilResolution: DoctorCouncilResolution) {
    this.isLoading = true;
    councilResolution.id = this.councilResolution.id;
    councilResolution.modifiedDate = new Date();
    councilResolution.modifiedBy = this.loggedUserId;
    councilResolution.createdBy = this.councilResolution.createdBy;
    councilResolution.createdDate = this.councilResolution.createdDate;
    councilResolution.doctorId = this.doctorId;
    councilResolution.councilResolutionStatusId = councilResolution.councilResolutionStatusId == 0 ? null : councilResolution.councilResolutionStatusId;
    this.councilResolutionsService.updateCouncilResolution(councilResolution.id, councilResolution).subscribe(
      (data: any) => {
        this.successToast('updateCouncilResolution');
        this.returnResponse(data._data);
      },
      (error) => {
        this.failToast(error._message);
        this.isLoading = false;
      }
    );
  }

  handleResultSelected(result) {
    if (result) {
      this.search = result.name + ' ' + result.surname1 + ' ' + result.surname2;
      this.councilResolutionForm.controls.doctorId.setValue(this.search);
      this.doctorId = result.id;
    }
  }

  successToast(messageTranslationKey) {
    this.translationService.get(['success', messageTranslationKey]).subscribe((translations: any) => {
      this.toastService.success(translations[messageTranslationKey], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }

  failToast(message) {
    this.translationService.get(['errorTitle', message]).subscribe((translations: any) => {
      this.toastService.error(translations[message], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }
  get doctorid() {
    return this.councilResolutionForm.get('doctorId');
  }
  get resolutionsType() {
    return this.councilResolutionForm.get('resolutionsType');
  }
  get topic() {
    return this.councilResolutionForm.get('topic');
  }
  get collegiateNumber() {
    return this.councilResolutionForm.get('collegiateNumber');
  }
  get part1() {
    return this.councilResolutionForm.get('part1');
  }
  get part2() {
    return this.councilResolutionForm.get('part2');
  }
  get part3() {
    return this.councilResolutionForm.get('part3');
  }
  get part4() {
    return this.councilResolutionForm.get('part4');
  }
  get observations() {
    return this.councilResolutionForm.get('observations');
  }
  get isLegalAppeal() {
    return this.councilResolutionForm.get('isLegalAppeal');
  }
  get councilResolutionStatusId() {
    return this.councilResolutionForm.get('councilResolutionStatusId');
  }

}
