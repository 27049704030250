import { Injectable } from '@angular/core';
import { CourseEndPoints, StudentEndPoints, ModuleEndPoints,
  PriceDetailsEndPoints } from '../../../constants/api-endpoints';
import { Course } from '../../../models/dashboard/course.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Student } from '../../../models/course/student.model';
import { CourseModule } from '../../../models/course/course-module.model';
import { PriceList } from '../../../models/course/price-list';
import { PriceDetails } from '../../../models/course/price-details';
import { PriceAdditionalData } from '../../../models/course/price-additional-data';
import { ApiService } from '../../common/services/api.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CoursePageService {
  courseUrls = CourseEndPoints;
  studentUrls = StudentEndPoints;
  moduleUrls = ModuleEndPoints;
  priceDetailUrls = PriceDetailsEndPoints;

  isActiveCourseSelected = false;
  isTodayCourseSelected = false;
  isNewCourseSelected = false;
  isFinishCourseSelected = false;
  selectedCourseId: number;
  localeId = 'en';
  courseTypeId = 0;
  isCourseSave = false;
  isModuleSave = false;
  moduleData: CourseModule;
  courseData: Course;
  isAutoLoadModule = false;
  isAutoLoadPrice = false;
  isCourseLoading = false;
  isSelectActiveTab = true;

  constructor(private _apiService : ApiService) {
    this.moduleData = new CourseModule();
    this.courseData = new Course();
   }

  getCourseById(id: number): Observable<Course> {
    return this._apiService.get(this.courseUrls.getCourseById + id, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getCourseList(): Observable<Course> {
    return this._apiService.get(this.courseUrls.getCourseList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveCourse(course: Course): Observable<Course> {
    return this._apiService.post(this.courseUrls.saveCourse, course, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateCourse(id: number, course: Course): Observable<Course> {
    return this._apiService.put(this.courseUrls.updateCourse + id, course, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deleteCourse(id: number): Observable<Course> {
    return this._apiService.delete(this.courseUrls.deleteCourse, id.toString(), true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getTodayCourseModuleList(): Observable<Course> {
    return this._apiService.get(this.courseUrls.getTodayCourseModuleList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getActiveCoursesList(): Observable<Course> {
    return this._apiService.get(this.courseUrls.getActiveCoursesList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getNewCoursesList(): Observable<Course> {
    return this._apiService.get(this.courseUrls.getNewCoursesList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getFinishCoursesListWithFilter(fromDate: string, toDate: string): Observable<Course> {
    let url = this.courseUrls.getFinishCoursesListWithFilter;
    url = url.replace('{fromDate}/{toDate}', fromDate.toString() + '/' + toDate.toString());
    return this._apiService.get(url, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getFinishCoursesList(fromDate: string, toDate: string): Observable<Course> {
    let url = this.courseUrls.getFinishCoursesList;
    url = url.replace('{fromDate}/{toDate}', fromDate.toString() + '/' + toDate.toString());
    return this._apiService.get(url, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveStudent(student: Student): Observable<Student> {
    return this._apiService.post(this.studentUrls.saveStudent, student, true, true).pipe(
          map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateStudent(student: Student): Observable<Student> {
    return this._apiService.put(this.studentUrls.updateStudent, student, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateStudentGeneric(id: number, student: Student): Observable<Student> {
    return this._apiService.put(this.studentUrls.updateStudentGeneric + id, student, true, true).pipe(
     map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deleteStudent(id: number): Observable<Student> {
    return this._apiService.delete(this.studentUrls.deleteStudent, id.toString(), true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getStudentById(id: number): Observable<Course> {
    return this._apiService.get(this.studentUrls.getStudentById + id, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getStudentListByCourseId(courseId: number): Observable<Course> {
    return this._apiService.get(this.studentUrls.getStudentListByCourseId + courseId, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  // module APIs
  getModuleById(id: number): Observable<CourseModule> {
    return this._apiService.get(this.moduleUrls.getModuleById + id, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveModule(courseModule: CourseModule): Observable<CourseModule> {
    return this._apiService.post(this.moduleUrls.saveModule, courseModule, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateModule(id: number, courseModule: CourseModule): Observable<CourseModule> {
    return this._apiService.put(this.moduleUrls.updateModule + id, courseModule, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deleteModule(id: number): Observable<CourseModule> {
    return this._apiService.delete(this.moduleUrls.deleteModule, id.toString(), true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getCourseModuleListByCourseId(courseId: number): Observable<CourseModule> {
    return this._apiService.get(this.moduleUrls.getCourseModuleListByCourseId + courseId, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  // price list APIs
  savePriceList(priceList: PriceList): Observable<PriceList> {
    return this._apiService.post(this.priceDetailUrls.savePriceList, priceList, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deletePriceList(id: number): Observable<PriceList> {
    return this._apiService.delete(this.priceDetailUrls.deletePriceList, id.toString(), true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getPriceListByModuleId(courseId: number, moduleId: number, courseTypeId: number): Observable<PriceList> {
    let url = this.priceDetailUrls.getPriceListByModuleId;
    url = url.replace('{courseId}/{moduleId}/{courseTypeId}', courseId + '/' + moduleId + '/' + courseTypeId);
    return this._apiService.get(url, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  savePriceDetails(priceDetails: PriceDetails): Observable<PriceDetails> {
    return this._apiService.post(this.priceDetailUrls.savePriceDetails, priceDetails, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updatePriceDetails(priceDetails: PriceDetails): Observable<PriceDetails> {
    return this._apiService.put(this.priceDetailUrls.updatePriceDetails, priceDetails, true, true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deletePriceDetail(id: number): Observable<PriceDetails> {
    return this._apiService.delete(this.priceDetailUrls.deletePriceDetail, id.toString(), true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deletePriceAdditionalDataByPriceId(moduleId: number): Observable<PriceAdditionalData> {
    return this._apiService.delete(this.priceDetailUrls.deletePriceAdditionalDataByPriceId, moduleId.toString(), true).pipe(
        map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateStudents(moduleId, selectedStudents){
    let url = this.studentUrls.updateAttendanceAndPaymentStatus;
    url = url.replace('{id}', moduleId.toString())
    return this._apiService.post(url, selectedStudents, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getStudentsList(): Observable<Student> {
    let url = this.studentUrls.getStudentsList;
    return this._apiService.get(url, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getStudentsListPaginated(pageSize: number, page: number, filters: any): Observable<Course> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('filter', filters.courseDenominationId + ';' + filters.instructorId + ';' + filters.studentId + ';' + filters.telephone + ';' + filters.isMember + ';' + filters.registrationStatus + ';' + filters.paymentMethod + ';' + filters.paymentStatus + ';' + filters.doctorId);
      const query = params.toString().replace('+', '%20');

    return this._apiService.get(this.courseUrls.getCourseListWithAttendeesPagination + '?' + `${query}`, true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  studentListExport(pageSize: number, page: number, filters: any): Observable<Course> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('filter', filters.courseDenominationId + ';' + filters.instructorId + ';' + filters.studentId + ';' + filters.telephone + ';' + filters.isMember + ';' + filters.registrationStatus + ';' + filters.paymentMethod + ';' + filters.paymentStatus  + ';' + filters.doctorId);
      const query = params.toString().replace('+', '%20');
      return this._apiService.get(this.courseUrls.studentListExport + '?' + `${query}`, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  private returnResponse(response) {
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

}
